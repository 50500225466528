import { useEffect, useMemo, useState } from "react";
import axios from "axios";

export const useGetFormulasIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_formulas_indicator.php?indicator=${indicatorId}`, [indicatorId]);


    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        }
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useGetCountryOrganizations = (countryId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_country_organizations.php?country=${countryId}`, [countryId]);

    useEffect(() => {
        if (!countryId) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [countryId]);

    return { data, isLoading }
}

export const useGetOrganizationCountries = (organizationId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_organization_countries.php?organization=${organizationId}`, [organizationId]);

    useEffect(() => {
        if (!organizationId) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [organizationId]);

    return { data, isLoading }
}