
const getIncludeAnalyticsObject = (analyticsObjectKey, selectedAnalyticsObjects) => {
    if (!selectedAnalyticsObjects || selectedAnalyticsObjects.length === 0) return false;
    return !!selectedAnalyticsObjects.find((item) => item?.bitrixID === analyticsObjectKey);
}

const mergeObject = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    const keys = new Set([...obj1Keys, ...obj2Keys]);
    return [...keys].reduce((acc, key) => {
        const value1 = obj1[key] ?? 0;
        const value2 = obj2[key] ?? 0;
        return {
            ...acc,
            [key]: Number(value1) + Number(value2)
        }
    }, {});
}

const getSummValueLabel = (analyticsObjectName, indicator, country, compareType) => {
    if (compareType === 'indicator') {
        return `${indicator?.name} | ${analyticsObjectName}`
    }
    if (compareType === 'country') {
        return `${country?.NAME} | ${analyticsObjectName}`
    }
    return analyticsObjectName;
}

const getSumValue = (data, compareType = null) => {
    const country = data.country;
    const indicator = data.indicator;
    const analiticsObjectName = `Все ${indicator?.elementsName}`;
    const label = getSummValueLabel(analiticsObjectName, indicator, country, compareType);
    // const tooltipLabel = showCountryName ? `${country?.NAME} | ${analiticsObjectName}` : analiticsObjectName;
    const unit = indicator?.unit;

    const sDataKeys = Object.keys(data.data);
    const summObject = sDataKeys.reduce((acc, analyticsObjectKey) => {
        const currentData = data.data[analyticsObjectKey];
        return mergeObject(acc, currentData)
    }, {});

    const summObjectArray = Object.entries(summObject);
    const sumValuesByYear = summObjectArray.map((item) => {
        const [columnName, value] = item;
        return { columnName, value: Number(value), tooltipLabel: label, unit }
    });

    // const seriesName = showCountryName ? `${country?.NAME} | ${analiticsObjectName}` : analiticsObjectName;
    return {
        seriesName: label,
        seriesData: sumValuesByYear,
    }
}

export const prepareSData = (data, selectedAnalyticsObjects, compareSData, showCountryName = false) => {
    showCountryName = compareSData ?? showCountryName;
    if (!data || !data.data) return [];
    const analyticsObjects = data.analyticsObjects;
    const sDataKeys = Object.keys(data.data);
    const country = data.country;
    const indicator = data.indicator;

    const hasSumValue = selectedAnalyticsObjects.find((item) => item.bitrixID == 0);
    let summData = {};
    if (hasSumValue) {
        summData = getSumValue(data, showCountryName);
    }

    const filteredSDataKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));

    const chartData = filteredSDataKeys.map((analyticsObjectKey) => {
        const currentAnalyticsObject = analyticsObjects.find((item) => item.bitrixID === analyticsObjectKey);
        const currentData = data.data[analyticsObjectKey];
        const sDataArray = Object.entries(currentData);
        const tooltipLabel = showCountryName ? `${country?.NAME} | ${currentAnalyticsObject?.name}` : currentAnalyticsObject?.name;
        const unit = indicator?.unit;

        const chartValues = sDataArray.map((sData) => {
            const [columnName, value] = sData;
            return { columnName, value: Number(value), tooltipLabel, unit }
        });

        const seriesName = showCountryName ? `${country?.NAME} | ${currentAnalyticsObject?.name}` : currentAnalyticsObject?.name;
        return {
            seriesName: seriesName,
            seriesData: chartValues,
        }
    });

    if (compareSData) {
        const compareChartData = prepareSData(compareSData, selectedAnalyticsObjects, undefined, showCountryName);

        if (hasSumValue) {
            return [...chartData, summData, ...compareChartData];
        }

        return [...chartData, ...compareChartData];
    }

    if (hasSumValue) {
        return [...chartData, summData];
    }

    return chartData;

}

export const prepareSDataTreemap = (data, year, params, selectedAnalyticsObjects) => {
    if (!data || !data.data) return [];

    const analyticsObjects = data.analyticsObjects;
    const sDataKeys = Object.keys(data.data);
    const indicator = data.indicator;


    const includesKeys = sDataKeys.filter((analyticsObjectKey) => !getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));
    const exludesKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));

    const chartValues = includesKeys.map((analyticsObjectKey) => {
        const currentAnalyticsObject = analyticsObjects.find((item) => item.bitrixID === analyticsObjectKey);
        const currentData = data.data[analyticsObjectKey];
        const value = currentData[year] ?? 0;
        const tooltipLabel = `${currentAnalyticsObject?.name} | ${year}`;
        const unit = indicator?.unit;
        const columnName = currentAnalyticsObject?.name;

        return {
            columnName: columnName,
            value: Number(value),
            tooltipLabel,
            unit
        }
    });

    if (exludesKeys.length && params?.sumOther) {
        const tooltipLabel = `Другие | ${year}`;
        const unit = indicator?.unit;

        const summValue = exludesKeys.reduce((acc, analyticsObjectKey) => {
            const currentData = data.data[analyticsObjectKey];
            return acc + (Number(currentData[year] ?? 0));
        }, 0);

        const otherChartValue = {
            columnName: 'Другие',
            value: Number(summValue),
            tooltipLabel,
            unit
        };

        return [...chartValues, otherChartValue].sort((a, b) => {
            if (params.sortDesc) return Number(a.value) - Number(b.value)
            return Number(b.value) - Number(a.value)
        })
    }

    return chartValues.sort((a, b) => {
        if (params.sortDesc) return Number(a.value) - Number(b.value)
        return Number(b.value) - Number(a.value)
    });
}

export const getSDataTreemapYear = (data, selectedYear) => {
    if (selectedYear) return selectedYear;
    const sDataKeys = Object.keys(data.data);
    const yearValues = sDataKeys.flatMap((analyticsObjectKey) => {
        const currentData = data.data[analyticsObjectKey];
        return Object.keys(currentData);
    });
    const yearsSet = new Set(yearValues);
    return Math.max(...yearsSet);
}


//-----------------------------------------------------------------------------

const getCrossingAnaliticsObjectKeys = (analyticsObjects, analyticsObjectKeys) => {
    const currentKeys = analyticsObjects?.map((item) => item.bitrixID);
    return analyticsObjectKeys?.filter((analyticsObjectKey) => currentKeys.includes(analyticsObjectKey));

}

const getSeriesLabel = (analyticsObject, indicator, country, compareType) => {
    if (compareType === 'indicator') {
        return `${indicator?.name} | ${analyticsObject?.name}`
    }
    if (compareType === 'country') {
        return `${country?.NAME} | ${analyticsObject?.name}`
    }
    return analyticsObject?.name;
}

const getYears = (data, analyticsObjectKeys, compareData) => {
    if (!data || !data.data) return [];

    const sDataObjects = data.data;
    const includedData = analyticsObjectKeys.map((sDataObjectKey) => sDataObjects[sDataObjectKey]);
    const years = includedData.flatMap((item) => Object.keys(item));

    const compareSDataObjects = compareData?.data ?? [];
    const compareIncludedData = compareData?.data ? analyticsObjectKeys.map((sDataObjectKey) => compareSDataObjects[sDataObjectKey]) : [];
    const filteredCompareIncludedData = compareIncludedData.filter((item) => Boolean(item));
    const compareYears = filteredCompareIncludedData.flatMap((item) => Object.keys(item));

    const yearSet = new Set([...years, ...compareYears]);

    return [...yearSet].sort((a, b) => Number(a) - Number(b));
}

const prepareSeriesData = (data, analyticsObjectKeys, years, compareType = null) => {
    if (!data || !data.data) return [];
    const analyticsObjects = data.analyticsObjects;
    const country = data.country;
    const indicator = data.indicator;
    const unit = indicator?.unit;
    const crossingAnaliticsObjectKeys = getCrossingAnaliticsObjectKeys(analyticsObjects, analyticsObjectKeys);

    return crossingAnaliticsObjectKeys.map((analyticsObjectKey) => {
        const currentData = data.data[analyticsObjectKey];
        const currentAnalyticsObject = analyticsObjects.find((item) => item.bitrixID === analyticsObjectKey);
        const label = getSeriesLabel(currentAnalyticsObject, indicator, country, compareType);
        const seriesValues = years.map((year) => {
            return {
                columnName: year,
                value: currentData?.[year] ? Number(currentData?.[year]) : null,
                tooltipLabel: label,
                unit
            }
        });

        return {
            seriesName: label,
            seriesData: seriesValues,
        }
    });
}

const getCompareType = (data, compareData) => {
    if (!compareData) return null;
    if (data?.indicator?.bitrixID === compareData?.indicator?.bitrixID) return 'country';
    return 'indicator'
}

const prepareFormulaSeries = (formulaSData, baseData) => {
    if (!formulaSData?.formula || !formulaSData?.data) return [];
    const indicator = baseData?.indicator;

    const { formula, data, analiticObject } = formulaSData;
    const title = `${formula?.name} | ${analiticObject?.name}`;

    const seriesData = data?.map((item) => ({
        columnName: item.year,
        tooltipLabel: title,
        unit: formula?.unit ?? indicator?.unit,
        value: Number(item?.value ?? 0),
    }));

    const formulaSeriesItem = {
        seriesData: seriesData,
        seriesName: title,
    };

    return formulaSeriesItem;
}

export const prepareSDataV2 = (data, selectedAnalyticsObjects, compareData, yearRange) => {
    if (!data || !data.data) return [];
    const sDataKeys = Object.keys(data.data);

    const includeSumValue = selectedAnalyticsObjects.find((item) => item.bitrixID == 0);
    const compareType = getCompareType(data, compareData);
    const filteredSDataKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));

    const years = getYears(data, filteredSDataKeys, compareData);
    const limitedYears = years?.filter((item) => {
        const [yearFrom, yearTo] = yearRange;
        const currentYear = Number(item);
        return currentYear >= yearFrom && yearFrom <= yearTo;
    });

    const chartData = prepareSeriesData(data, filteredSDataKeys, limitedYears, compareType);
    const compareChartData = prepareSeriesData(compareData, filteredSDataKeys, limitedYears, compareType);
    // const formulaChartData = prepareFormulaSeries(formulaSData);

    if (includeSumValue) {
        const sumData = getSumValue(data, compareType);
        if (Boolean(compareType)) {
            const compareSumData = getSumValue(compareData, compareType);
            return [...chartData, sumData, ...compareChartData, compareSumData];
        }
        return [...chartData, sumData];
    }

    return [...chartData, ...compareChartData];

}

const getCategoriesTooltipLabel = (indicator, country, compareType) => {
    if (compareType === 'indicator') {
        return `${indicator?.name} | `
    }
    if (compareType === 'country') {
        return `${country?.NAME} | `
    }
    return '';
}

const getCategoriesSeriesLabel = (indicator, country, compareType) => {
    if (compareType === 'indicator') {
        return `${indicator?.name}`
    }
    if (compareType === 'country') {
        return `${country?.NAME} | ${indicator?.name}`
    }
    return `${indicator?.name}`;
}

const prepareSeriesDataCategories = (data, analyticsObjectKeys, year, compareType = null) => {
    if (!data || !data?.data) return null;

    const analyticsObjects = data.analyticsObjects;
    const indicator = data.indicator;
    const country = data.country;
    const unit = indicator?.unit;

    const seriesValues = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
        const currentData = data.data[analyticsObjectKey];
        const currentAnalyticsObject = analyticsObjects.find((item) => item.bitrixID === analyticsObjectKey);
        const tooltipLabel = getCategoriesTooltipLabel(indicator, country, compareType);
        const value = {
            columnName: currentAnalyticsObject?.name,
            value: currentData?.[year] ? Number(currentData?.[year]) : null,
            tooltipLabel: tooltipLabel,
            unit
        };
        return [...acc, value];
    }, []);

    const seriesName = getCategoriesSeriesLabel(indicator, country, compareType);

    return {
        seriesName: seriesName,
        seriesData: seriesValues,
    }

}

export const prepareSDataCategories = (data, year, selectedAnalyticsObjects, compareData) => {
    if (!data || !data.data) return [];
    if (!selectedAnalyticsObjects.length) return [];

    const compareType = getCompareType(data, compareData);
    const sDataKeys = Object.keys(data.data);
    const filteredSDataKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));

    const seriesData = prepareSeriesDataCategories(data, filteredSDataKeys, year, compareType);
    const compareSeriesData = prepareSeriesDataCategories(compareData, filteredSDataKeys, year, compareType);

    if (compareSeriesData) {
        return [seriesData, compareSeriesData];
    }

    return [seriesData];
}

//--------------------------------------------------------------------------------------
const checkAnalyticsObject = (analyticsObject, parentId) => {
    if (analyticsObject.parent === null && parentId !== null) return null;
    if (analyticsObject.parent?.bitrixID == parentId) return analyticsObject;
    return checkAnalyticsObject(analyticsObject.parent, parentId);
}

export const getAnalyticsObjectsTree = (analyticsObjects, parentId = null) => {
    const analyticsObjectsTree = analyticsObjects.reduce((acc, analyticsObject) => {
        const object = checkAnalyticsObject(analyticsObject, parentId);

        if (!object) return acc;
        const alredyExist = acc.find((accItem) => accItem.bitrixID == object.bitrixID);
        if (alredyExist) return acc;
        const childrens = getAnalyticsObjectsTree(analyticsObjects, object.bitrixID);
        const resultItem = {
            bitrixID: object.bitrixID,
            name: object.name,
            sort: object.sort,
            childrens: childrens
        }
        return [...acc, resultItem]
    }, []);

    return analyticsObjectsTree;
}
// НЕ ИСПОЛЬЗУЕТСЯ 
const prepareSeriesDataTreemap = (tree, data, year, selectedAnalyticsObjects) => {

    const seriesData = tree?.reduce((acc, treeItem) => {
        if (treeItem.childrens.length > 0) {
            const childrenSeriesData = prepareSeriesDataTreemap(treeItem.childrens, data, year, selectedAnalyticsObjects);
            return { ...acc, [treeItem.name]: childrenSeriesData }
        }

        return {
            ...acc, [treeItem.name]: 10
            // value: Number(summValue),
            // tooltipLabel,
            // unit
        };

    }, {});

    return seriesData;
}

const getLimitedTree = (tree, analyticsObjectKeys) => {
    if (!analyticsObjectKeys || analyticsObjectKeys?.length == 0) {
        return tree;
    }

    const limitedTree = tree.reduce((acc, treeItem) => {
        if (analyticsObjectKeys.includes(treeItem.bitrixID)) {
            return [...acc, ...treeItem.childrens];
        }

        const limitedChildTree = getLimitedTree(treeItem.childrens, analyticsObjectKeys);
        return [...acc, ...limitedChildTree];
    }, []);

    return limitedTree;
}


// НЕ ИСПОЛЬЗУЕТСЯ 
const prepareSeriesDataTreemapArray = (tree, data, year, selectedAnalyticsObjects) => {

    const unit = data?.indicator?.unit;

    const seriesData = tree?.map((treeItem) => {
        if (treeItem.childrens.length > 0) {
            const childrenSeriesData = prepareSeriesDataTreemapArray(treeItem.childrens, data, year, selectedAnalyticsObjects);

            const summValue = childrenSeriesData.reduce((acc, item) => {
                return acc + item.value ?? 0;
            }, 0)

            return {
                columnName: treeItem.name,
                value: summValue,
                children: childrenSeriesData,
                tooltipLabel: treeItem.name,
                unit
            }
        }
        const currentData = data?.data[treeItem.bitrixID] ?? {};
        const value = currentData?.[year] ?? 0;

        return {
            columnName: treeItem.name,
            value: Number(value),
            children: null,
            tooltipLabel: treeItem.name,
            unit
        }

    });

    return seriesData;
}

const prepareSeriesDataTreemapArrayV2 = (tree, data, year, params, analyticsObjectKeys) => {

    const unit = data?.indicator?.unit;

    const seriesData = tree?.reduce((acc, treeItem) => {
        const currentData = data?.data[treeItem.bitrixID] ?? {};
        const value = currentData?.[year] ?? 0;
        const skipItem = analyticsObjectKeys.includes(treeItem.bitrixID);

        if (skipItem) {
            if (!params?.sumOther) {
                return acc;
            }
            const other = acc.find((item) => item.columnName === 'Другие');
            const filteredAcc = acc.filter((item) => item.columnName !== 'Другие');
            let summValue = 0;
            if (treeItem.childrens.length > 0) {
                const childrenSeriesData = prepareSeriesDataTreemapArrayV2(treeItem.childrens, data, year, params, analyticsObjectKeys);
                summValue = childrenSeriesData.reduce((acc, item) => {
                    return acc + item.groupValue ?? 0;
                }, 0)

            }

            const otherItem = {
                columnName: 'Другие',
                value: Number(value) + Number(other?.value ?? 0) + summValue,
                groupValue: Number(value) + Number(other?.value ?? 0) + summValue,
                children: null,
                tooltipLabel: 'Другие',
                unit
            }

            return [...filteredAcc, otherItem];
        }

        if (treeItem.childrens.length > 0) {
            const childrenSeriesData = prepareSeriesDataTreemapArrayV2(treeItem.childrens, data, year, params, analyticsObjectKeys);

            const summValue = childrenSeriesData.reduce((acc, item) => {
                return acc + Number(item.groupValue ?? 0);
            }, 0)

            return [
                ...acc,
                {
                    columnName: treeItem.name,
                    // value: summValue,
                    value: null,
                    groupValue: summValue,
                    children: childrenSeriesData,
                    tooltipLabel: treeItem.name,
                    unit
                }
            ]
        }

        return [
            ...acc,
            {
                columnName: treeItem.name,
                value: Number(value),
                groupValue: Number(value),
                children: null,
                tooltipLabel: treeItem.name,
                unit
            }
        ]
    }, []);

    // return seriesData;
    return [...seriesData]
        .sort((a, b) => {
            if (params.sortDesc) return Number(a.groupValue) - Number(b.groupValue)
            return Number(b.groupValue) - Number(a.groupValue)
        })
}

const prepareSDataTreemapBase = (data, year, params) => {
    const analyticsObjects = data?.analyticsObjects;
    const analyticsObjectKeys = analyticsObjects?.map((item) => item.bitrixID) ?? [];
    const indicator = data.indicator;
    const unit = indicator?.unit;

    const seriesValues = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
        const currentData = data.data[analyticsObjectKey];
        const currentAnalyticsObject = analyticsObjects.find((item) => item.bitrixID === analyticsObjectKey);
        const value = {
            columnName: currentAnalyticsObject?.name,
            value: currentData?.[year] ? Number(currentData?.[year]) : null,
            tooltipLabel: currentAnalyticsObject?.name,
            unit
        };
        return [...acc, value];
    }, []);

    return [...seriesValues]
        .sort((a, b) => {
            if (params.sortDesc) return Number(a.value) - Number(b.value)
            return Number(b.value) - Number(a.value)
        });
}

export const prepareSDataTreemapV2 = (data, year, params, selectedAnalyticsObjects) => {
    if (!data || !data?.data) return null;

    if (params?.groupsDisabled) {
        return prepareSDataTreemapBase(data, year, params);
    }

    const sDataKeys = Object.keys(data.data);
    // const analyticsObjectKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));
    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);

    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const seriesData = prepareSeriesDataTreemapArrayV2(tree, data, year, params, analyticsObjectKeys);

    return seriesData;
}

const sliceSDataTreemap = (seriesData, params, indicator) => {
    const limit = params?.itemsCount;
    const unit = indicator?.unit;
    if (!limit || limit === '') {
        return seriesData;
    };

    const slicedSeriesData = seriesData.slice(0, limit);

    if (!params?.sumOther) {
        return slicedSeriesData;
    }

    const otherSeriesData = seriesData.slice(limit, seriesData.length);

    const sum = otherSeriesData.reduce((acc, item) => {
        const itemValue = item?.groupValue ?? item?.value;
        return acc + itemValue
    }, 0);

    const childrens = otherSeriesData.reduce((acc, item) => [...acc, ...item?.children ?? []], []);

    const otherItem = {
        columnName: 'Другие',
        value: childrens.length > 0 ? null : sum,
        groupValue: sum,
        children: childrens,
        tooltipLabel: 'Другие',
        unit
    }

    return [...slicedSeriesData, ...(otherSeriesData?.length > 0 ? [otherItem] : [])]
        .sort((a, b) => {
            if (params.sortDesc) return Number(a.groupValue) - Number(b.groupValue)
            return Number(b.groupValue) - Number(a.groupValue)
        });
}

export const prepareSDataTreemapV3 = (data, year, params, selectedAnalyticsObjects) => {
    if (!data || !data?.data) return null;

    if (params?.groupsDisabled) {
        const baseSeriesData = prepareSDataTreemapBase(data, year, params);
        const slicedSeriesData = sliceSDataTreemap(baseSeriesData, params, data?.indicator);
        return slicedSeriesData;
    }

    const sDataKeys = Object.keys(data.data);
    // const analyticsObjectKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));
    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);

    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const limitedTree = getLimitedTree(tree, analyticsObjectKeys);

    const seriesData = prepareSeriesDataTreemapArrayV2(limitedTree, data, year, params, []);

    const slicedSeriesData = sliceSDataTreemap(seriesData, params, data?.indicator);

    return slicedSeriesData;
}

//----------------------------------------------------------------------------------
const prepareSeriesDataPieArray = (tree, data, year, params, analyticsObjectKeys) => {

    const unit = data?.indicator?.unit;

    const seriesData = tree?.reduce((acc, treeItem) => {
        const currentData = data?.data[treeItem.bitrixID] ?? {};
        const value = currentData?.[year] ?? 0;
        const skipItem = analyticsObjectKeys.includes(treeItem.bitrixID);

        if (skipItem) {
            if (!params?.sumOther) {
                return acc;
            }
            const other = acc.find((item) => item.columnName === 'Другие');
            const filteredAcc = acc.filter((item) => item.columnName !== 'Другие');
            let summValue = 0;
            if (treeItem.childrens.length > 0) {
                const childrenSeriesData = prepareSeriesDataPieArray(treeItem.childrens, data, year, params, analyticsObjectKeys);
                summValue = childrenSeriesData.reduce((acc, item) => {
                    return acc + item.value ?? 0;
                }, 0)

            }

            const otherItem = {
                columnName: 'Другие',
                value: Number(value) + Number(other?.value ?? 0) + summValue,
                children: null,
                tooltipLabel: 'Другие',
                unit
            }

            return [...filteredAcc, otherItem];
        }

        if (treeItem.childrens.length > 0) {
            const childrenSeriesData = prepareSeriesDataPieArray(treeItem.childrens, data, year, params, analyticsObjectKeys);

            const summValue = childrenSeriesData.reduce((acc, item) => {
                return acc + Number(item.value ?? 0);
            }, 0)

            return [
                ...acc,
                {
                    columnName: treeItem.name,
                    value: summValue,
                    // children: childrenSeriesData,
                    tooltipLabel: treeItem.name,
                    unit
                }
            ]
        }

        return [
            ...acc,
            {
                columnName: treeItem.name,
                value: Number(value),
                // children: null,
                tooltipLabel: treeItem.name,
                unit
            }
        ]
    }, []);

    // return seriesData;
    return [...seriesData].sort((a, b) => {
        if (params.sortDesc) return Number(a.value) - Number(b.value)
        return Number(b.value) - Number(a.value)
    });
}

export const prepareSDataPie = (data, year, params, selectedAnalyticsObjects) => {
    if (!data || !data?.data) return null;


    const sDataKeys = Object.keys(data.data);
    // const analyticsObjectKeys = sDataKeys.filter((analyticsObjectKey) => getIncludeAnalyticsObject(analyticsObjectKey, selectedAnalyticsObjects));
    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);

    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const limitedTree = getLimitedTree(tree, analyticsObjectKeys);

    const seriesData = prepareSeriesDataPieArray(limitedTree, data, year, params, []);
    const slicedSeriesData = sliceSDataTreemap(seriesData, params, data?.indicator);

    return slicedSeriesData;
}

//--------------------------------------------------------------------------------

export const getRaceYearLimit = (sData) => {
    let minYear = 9999;
    let maxYear = 0;
    if (!sData) return [minYear, maxYear];

    const sDataEntries = Object.entries(sData);

    sDataEntries.forEach((sDataItem) => {
        const [_, valueObject] = sDataItem;
        const entries = Object.entries(valueObject);

        entries.forEach((item) => {
            const [year, _] = item;

            if (Number(year) > maxYear) {
                maxYear = Number(year);
            }

            if (Number(year) < minYear) {
                minYear = Number(year);
            }
        });

    });

    return [minYear, maxYear];
}

const getBaseYearValues = (analyticsObjects) => {
    const baseYearValues = analyticsObjects?.reduce((acc, item) => {
        return { ...acc, [item.name]: 0 }
    }, {})

    return baseYearValues;
}

const getRaceValuesByYear = (data, year, maxItems) => {
    const analyticsObjects = data?.analyticsObjects;
    const sData = data?.data;

    const sortedValue = analyticsObjects?.reduce((acc, item) => {
        const sDataItem = sData[item.bitrixID];
        return [
            ...acc,
            { bitrixID: item.bitrixID, value: Number(sDataItem?.[year] ?? 0) }
        ]
    }, [])
        .sort((a, b) => b.value - a.value)
        .slice(0, maxItems);

    const valuesByYear = analyticsObjects?.reduce((acc, item) => {
        // const sDataItem = sData[item.bitrixID];
        const sDataItem = sortedValue.find((sortedItem) => sortedItem.bitrixID === item.bitrixID);
        return { ...acc, [item.name]: sDataItem?.value ?? 0 }
    }, {})

    return valuesByYear;

}

/**
 * {
 *      год: {
 *          категория: значение
 *      }
 * }
 * 
 */
export const prepareSDataRace = (data, params, selectedAnalyticsObjects) => {
    if (!data || !data?.data) return null;
    const years = getRaceYearLimit(data?.data)
    const maxItems = params?.itemsCount ?? 10;

    const startYear = years[0];
    const stopYear = years[1];
    let currentYear = startYear;
    let resultSData = {};

    const baseYearValues = getBaseYearValues(data?.analyticsObjects);
    resultSData[currentYear - 1] = baseYearValues;

    while (currentYear <= stopYear) {
        const valuesByYear = getRaceValuesByYear(data, currentYear, maxItems);
        resultSData[currentYear] = valuesByYear;
        currentYear++;
    }

    return { chartData: resultSData, yearFrom: startYear, yearTo: stopYear };
}

//------------------------------------------------------------------------------

const getTreeElements = (tree, analyticsObjectKeys) => {
    if (!analyticsObjectKeys || analyticsObjectKeys?.length == 0) {
        return [];
    }

    const limitedTree = tree.reduce((acc, treeItem) => {
        if (analyticsObjectKeys.includes(treeItem.bitrixID)) {
            return [...acc, treeItem];
        }

        const limitedChildTree = getTreeElements(treeItem.childrens, analyticsObjectKeys);
        return [...acc, ...limitedChildTree];
    }, []);

    return limitedTree;
}

const getAnalyticsObjectKeys = (analyticsObjectTreeItem) => {
    if (!analyticsObjectTreeItem?.childrens?.length) {
        return [analyticsObjectTreeItem.bitrixID]
    }

    const analyticsObjectKeys = analyticsObjectTreeItem?.childrens?.reduce((acc, item) => {
        const childrenKeys = getAnalyticsObjectKeys(item);
        return [...acc, ...childrenKeys];
    }, []);

    return analyticsObjectKeys;
}

const getValues = (analyticsObjectTreeItem, data, years, compareType) => {
    const analyticsObjectKeys = getAnalyticsObjectKeys(analyticsObjectTreeItem);
    const [yearFrom, yearTo] = years;
    const unit = data?.indicator?.unit;

    const country = data.country;
    const indicator = data.indicator;
    const label = getSeriesLabel(analyticsObjectTreeItem, indicator, country, compareType);

    let valuesArray = [];
    let currentYear = yearFrom;
    while (currentYear <= yearTo) {

        const sumValue = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
            const currentValue = Number(data?.data?.[analyticsObjectKey]?.[currentYear] ?? 0);
            return acc + currentValue;
        }, 0);

        const valueItem = {
            columnName: `${currentYear}`,
            value: sumValue !== 0 ? sumValue : null,
            unit,
            tooltipLabel: label,
        };

        valuesArray.push(valueItem);
        currentYear++;
    }

    return valuesArray;
}

const prepareSDataTreeV1 = (data, selectedAnalyticsObjects, yearRange, compareType) => {
    if (!data || !data.data) return [];
    const country = data.country;
    const indicator = data.indicator;

    // const years = getRaceYearLimit(data?.data);
    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);

    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const limitedTree = getTreeElements(tree, analyticsObjectKeys);

    const sDataTreeSeries = limitedTree.reduce((acc, item) => {
        const label = getSeriesLabel(item, indicator, country, compareType);
        const values = getValues(item, data, yearRange, compareType);
        const seriesItem = {
            seriesName: label,
            seriesData: values,
        }
        return [...acc, seriesItem];
    }, []);

    const includeSumValue = selectedAnalyticsObjects.find((item) => item.bitrixID == 0);
    const sumData = getSumValue(data, compareType);

    if (includeSumValue) {
        return [sumData, ...sDataTreeSeries];
    }

    return sDataTreeSeries;
}

export const prepareSDataWithTree = (data, selectedAnalyticsObjects, compareSData, formulaSData, sliderParams) => {
    const yearRange = sliderParams?.yearRange;
    const isSumValue = data?.indicator?.sumValue;

    if (formulaSData?.length > 0) {
        const formulaSeries = formulaSData?.map((item) => {
            return prepareFormulaSeries(item, data);
        })
        return formulaSeries;
    }

    const compareType = getCompareType(data, compareSData);

    if (isSumValue) {
        // if(includeSumValue)
        // const formulaChartData = prepareFormulaSeries(formulaSData);
        const treeSeriesData = prepareSDataTreeV1(data, selectedAnalyticsObjects, yearRange, compareType);
        const compareTreeSeriesData = prepareSDataTreeV1(compareSData, selectedAnalyticsObjects, yearRange, compareType);
        return [...treeSeriesData, ...compareTreeSeriesData]
    }

    return prepareSDataV2(data, selectedAnalyticsObjects, compareSData, yearRange);
}

//-----------------------------------------------------------------------------------------------

const getValuesForRaceTree = (analyticsObjectTreeItem, data, years) => {
    const analyticsObjectKeys = getAnalyticsObjectKeys(analyticsObjectTreeItem);
    const [yearFrom, yearTo] = years;
    const unit = data?.indicator?.unit;

    let valuesArray = [];
    let currentYear = yearFrom;
    while (currentYear <= yearTo) {

        const sumValue = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
            const currentValue = Number(data?.data?.[analyticsObjectKey]?.[currentYear] ?? 0);
            return acc + currentValue;
        }, 0);

        const valueItem = {
            year: currentYear,
            value: sumValue,
        };

        valuesArray.push(valueItem);
        currentYear++;
    }

    return valuesArray;
}

const getBaseRaceTreeYearValues = (limitedTree) => {
    const baseYearValues = limitedTree?.reduce((acc, item) => {
        return { ...acc, [item.name]: 0 }
    }, {})

    return baseYearValues;
}


/** 
 * Сейчас не используется
 * Формирует даныне для race графика 
 * без учета maxItems параметра
*/
const getValuesForRaceTreeV2 = (limitedTree, data, currentYear) => {
    const sDataTreeSeries = limitedTree.reduce((acc, treeItem) => {
        const analyticsObjectKeys = getAnalyticsObjectKeys(treeItem);
        const sumValue = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
            const currentValue = Number(data?.data?.[analyticsObjectKey]?.[currentYear] ?? 0);
            return acc + currentValue;
        }, 0);

        const seriesItem = {
            [treeItem.name]: sumValue,
        }

        return { ...acc, ...seriesItem };
    }, {});

    return sDataTreeSeries;
}

const getValuesForRaceTreeV3 = (limitedTree, data, currentYear, maxItems) => {
    const limitedTreeData = limitedTree.reduce((acc, treeItem) => {
        const analyticsObjectKeys = getAnalyticsObjectKeys(treeItem);
        const sumValue = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
            const currentValue = Number(data?.data?.[analyticsObjectKey]?.[currentYear] ?? 0);
            return acc + currentValue;
        }, 0);

        const seriesItem = {
            name: treeItem.name,
            bitrixID: treeItem.bitrixID,
            value: sumValue,
        }

        return [...acc, seriesItem];
    }, [])
        .sort((a, b) => b.value - a.value)
        .slice(0, maxItems);

    const sDataTreeSeries = limitedTree.reduce((acc, treeItem) => {
        const sDataItem = limitedTreeData.find((sortedItem) => sortedItem.bitrixID === treeItem.bitrixID);
        const seriesItem = {
            [treeItem.name]: sDataItem?.value ?? 0,
        }

        return { ...acc, ...seriesItem };
    }, {});

    return sDataTreeSeries;
}


/**
 * {
 *      год: {
 *          категория: значение
 *      }
 * }
 * 
 */

export const prepareSDataRaceTree = (data, params, selectedAnalyticsObjects, sliderParams) => {
    if (!data || !data?.data) return null;
    // const years = getRaceYearLimit(data?.data)
    const years = sliderParams?.yearRange
    const maxItems = params?.itemsCount ?? 10;

    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);
    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const limitedTree = getLimitedTree(tree, analyticsObjectKeys);

    const startYear = years[0];
    const stopYear = years[1];
    let currentYear = startYear;
    let resultSData = {};

    const baseYearValues = getBaseRaceTreeYearValues(limitedTree);
    resultSData[currentYear - 1] = baseYearValues;

    while (currentYear <= stopYear) {
        // const valuesByYear = getRaceValuesByYear(data, currentYear, maxItems);
        const valuesByYear = getValuesForRaceTreeV3(limitedTree, data, currentYear, maxItems)
        resultSData[currentYear] = valuesByYear;
        currentYear++;
    }

    return { chartData: resultSData, yearFrom: startYear - 1, yearTo: stopYear };
}

export const getSDataChartTitle = ({ data, formulaSData, year }) => {
    const indicator = data?.indicator;
    const formula = formulaSData?.[0]?.formula;

    const indicatorName = indicator?.name ?? '';

    const formulaName = formula?.name ? ` | ${formula?.name}` : '';
    const yearString = year ? ` | ${year}` : '';
    const unit = ` | ${formula?.unit ?? indicator?.unit}`;

    return `${indicatorName}${formulaName}${yearString}${unit}`;
}

//--------------------------------------------------------------------------------

const getTreeElementsCategories = (tree, analyticsObjectKeys) => {
    if (!analyticsObjectKeys || analyticsObjectKeys?.length == 0) {
        return tree;
    }

    const limitedTree = tree.reduce((acc, treeItem) => {
        if (analyticsObjectKeys.includes(treeItem.bitrixID)) {
            return [...acc, treeItem];
        }

        const limitedChildTree = getTreeElementsCategories(treeItem.childrens, analyticsObjectKeys);
        return [...acc, ...limitedChildTree];
    }, []);

    return limitedTree;
}

const getSumValueByYear = (data, year, includeSumValue, compareType = null) => {
    if (!includeSumValue) return [];
    const country = data.country;
    const indicator = data.indicator;
    const analiticsObjectName = `Все ${indicator?.elementsName}`;
    const label = getSummValueLabel(analiticsObjectName, indicator, country, compareType);
    // const tooltipLabel = showCountryName ? `${country?.NAME} | ${analiticsObjectName}` : analiticsObjectName;
    const unit = indicator?.unit;

    const sDataKeys = Object.keys(data.data);
    const sumValue = sDataKeys.reduce((acc, analyticsObjectKey) => {
        const currentValue = data.data[analyticsObjectKey]?.[year];
        return acc + Number(currentValue ?? 0);
    }, 0);

    // const summObjectArray = Object.entries(summObject);
    // const sumValuesByYear = summObjectArray.map((item) => {
    //     const [columnName, value] = item;
    //     return { columnName, value: Number(value), tooltipLabel: label, unit }
    // });

    const seriesDataItem = {
        columnName: label,
        value: sumValue,
        tooltipLabel: '',
        unit
    }
    return [seriesDataItem];
}

const prepareSeriesDataCategoriesV2 = (limitedTree, data, currentYear, includeSumValue, params, compareType = null) => {
    if (!data || !data?.data) return null;

    const indicator = data.indicator;
    const country = data.country;
    const unit = indicator?.unit;

    const seriesValues = limitedTree.reduce((acc, treeItem) => {
        const analyticsObjectKeys = getAnalyticsObjectKeys(treeItem);
        const sumValue = analyticsObjectKeys.reduce((acc, analyticsObjectKey) => {
            const currentValue = Number(data?.data?.[analyticsObjectKey]?.[currentYear] ?? 0);
            return acc + currentValue;
        }, 0);

        const tooltipLabel = getCategoriesTooltipLabel(indicator, country, compareType);
        const value = {
            columnName: treeItem?.name,
            value: sumValue,
            tooltipLabel: tooltipLabel,
            unit
        };
        return [...acc, value];
    }, []);

    const seriesName = getCategoriesSeriesLabel(indicator, country, compareType);

    const sumData = getSumValueByYear(data, currentYear, includeSumValue, compareType);

    const sortedSeriesData = [
        ...sumData,
        ...seriesValues
    ]?.sort((a, b) => {
        if (params?.sortByValue) {
            if (params.sortDesc) return Number(a.value) - Number(b.value)
            return Number(b.value) - Number(a.value)
        }

        if (params?.sortDesc) return b.columnName.toLowerCase().localeCompare(a.columnName);
        return a.columnName.toLowerCase().localeCompare(b.columnName);
    });

    return {
        seriesName: seriesName,
        seriesData: sortedSeriesData,
    }
}

const prepareCategoriesFormulaSeries = (formulaSData, year, data, params) => {
    const indicator = data?.indicator;

    const seriesName = `${indicator?.name} | ${formulaSData?.[0]?.formula?.name}`;

    const seriesData = formulaSData?.reduce((acc, item) => {
        const dataItem = item?.data?.find((dataItem) => {
            return dataItem.year == year
        })

        const seriesItem = {
            columnName: item.analiticObject.name,
            tooltipLabel: `${formulaSData?.[0]?.formula?.name} | `,
            unit: item?.formula?.unit ?? indicator?.unit,
            value: dataItem?.value ?? null,
        };
        return [...acc, seriesItem];
    }, []);

    const sortedSeriesData = [...seriesData]?.sort((a, b) => {
        if (params?.sortByValue) {
            if (params.sortDesc) return Number(a.value) - Number(b.value)
            return Number(b.value) - Number(a.value)
        }

        if (params?.sortDesc) return b.columnName.toLowerCase().localeCompare(a.columnName);
        return a.columnName.toLowerCase().localeCompare(b.columnName);
    });

    return [{
        seriesName: seriesName,
        seriesData: sortedSeriesData,
    }]
}

export const prepareSDataCategoriesV2 = (data, year, selectedAnalyticsObjects, compareData, formulaSData, params) => {
    if (!data || !data.data) return [];

    if (formulaSData?.length > 0) {
        const formulaSeries = prepareCategoriesFormulaSeries(formulaSData, year, data, params);
        return formulaSeries;
    }

    const includeSumValue = selectedAnalyticsObjects.find((item) => item.bitrixID == 0);
    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);

    const analyticsObjects = data.analyticsObjects;
    const tree = getAnalyticsObjectsTree(analyticsObjects);
    const limitedTree = getTreeElementsCategories(tree, analyticsObjectKeys);

    const compareType = getCompareType(data, compareData);

    const seriesData = prepareSeriesDataCategoriesV2(limitedTree, data, year, includeSumValue, params, compareType);
    const compareSeriesData = prepareSeriesDataCategoriesV2(limitedTree, compareData, year, includeSumValue, params, compareType);

    if (compareSeriesData) {
        return [seriesData, compareSeriesData];
    }

    return [seriesData];
}