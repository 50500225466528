import { useCallback, useState } from "react";
import { getSDataChartTitle, getSDataTreemapYear, prepareSDataPie } from "../sDataPage.utils";

export const useSDataPie = (
    chartName,
    data,
    params,
    selectedAnalyticsObjects,
    selectedYear,
) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const year = getSDataTreemapYear(data, selectedYear);

        const chartData = prepareSDataPie(data, year, params, selectedAnalyticsObjects);
        const chartTitle = getSDataChartTitle({ data, year });

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/percent.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);

        strArray.push(`\tvar container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tcontainer.children.push(am5.Label.new(root, {
                text: '${chartTitle}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50,
            }));\r\n`);
        }

        strArray.push(`\tvar chart = container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout
            })
        );\r\n`);

        strArray.push(`\tvar series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: '${chartName}',
                categoryField: "columnName",
                valueField: "value",
                tooltip: am5.Tooltip.new(root, {
                    labelText: '{tooltipLabel}: {value} {unit}'
                })
            })
        );\r\n`);

        strArray.push(`\tseries.data.setAll(chartData);\r\n`);
        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                paddingLeft: 100,
                paddingRight: 100,
            }));\r\n`);
            strArray.push(`\tlegend.data.setAll(series.dataItems);\r\n`);
        }

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    ]);

    return { htmlDiagram, createChart }
}