
export const paramsSx = {
    // border: '1px solid #e0e0e0',
    // borderRadius: '10px',
    // padding: '10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: 'calc(70% - 20px)',
    '@media (max-width: 600px)': {
        width: '100%',
        alignItems: 'center',
    }
}

export const paramsTitleSx = {
    fontSize: '32px',
    fontWeight: '600',
    mb: '5px',
    '@media (max-width: 600px)': {
        fontSize: '24px',
        justifyContent: 'center',
    }
}

export const paramsTextField = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const compareSx = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: 'calc(30% - 20px)',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const compareTextSx = {
    fontSize: '32px',
    fontWeight: '600',
    mb: '5px',
    '@media (max-width: 600px)': {
        fontSize: '24px',
        justifyContent: 'center',
    }
}

export const mainGridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    flexDirection: 'row',
    gap: '10px',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
    }
}

export const headerGridSx = {
    paddingTop: '15px',
    paddingLeft: '60px',
    paddingRight: '60px',
    flexDirection: 'column',
    gap: '10px',
    mb: '30px',
    '@media (max-width: 600px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
    }
}

export const headerTextSx = {
    fontSize: '36px',
    fontWeight: '700',
    alignItems: 'baseline',
    width: 'auto',
    gap: '5px',
    '@media (max-width: 600px)': {
        width: '100%',
        mb: '10px',
        fontSize: '24px',
        alignItems: 'center',
    }
}

export const headerDescriptionTextSx = {
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '10px',
    maxWidth: '70%',
    '@media (max-width: 600px)': {
        maxWidth: 'unset',
        width: '100%',
    }
}