import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  useBaseTestChart,
  useChartParams,
  useGetCompareData,
  useGetCountriesByIndicator,
  useGetData,
  useGetCompareIndicators,
  useGetOneCountry,
  useGetOneIndicator,
  useSliderParams,
  useGetFormulasIndicator,
  useGetDataByFormula,
} from "./baseTestChart.hooks";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { Header } from "../../components/header";
import {
  compareSx,
  compareTextSx,
  headerDescriptionTextSx,
  headerGridSx,
  headerTextSx,
  mainGridSx,
  paramsSx,
  paramsTextField,
  paramsTitleSx,
} from "./baseTestChart.styles";
import { useNavigate, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { isColor } from "../../utils/chart.utils";
import { ChooseIndicator } from "../../components/сhooseIndicator";
import { FormulaSelector } from "../../components/formulaSelector/formulaSelector";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import { getFormulaName, getUnit, getUnitRate } from "./baseTestChart.utils";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";
import { ChartTypeLinks } from "../../components/chartTypeLinks";
import {
  useGetOneCountryQuery,
  useGetOneIndicatorQuery,
} from "../../store/api/info.api";
import { CountryAndIndicatorChoose } from "../../components/countryAndIndicatorChoose/countryAndIndicatorChoose";
import { useCheckAccess } from "../../hooks/app.hooks";
import { ChartTypeSelector } from "./chartTypeSelector";
const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const vizualizationType = "column";
export const BaseTestChart = () => {
  const navigate = useNavigate();
  const { indicatorId, countryId } = useParams();
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState({
    id: "all",
    label: "Весь мир",
  });
  const [chartType, setChartType] = useState("columns");
  const [compareIds, setCompareIds] = useState({
    indicatorId: null,
    countryId: null,
  });
  const [formula, setFormula] = useState(null);
  const { data: loadedData } = useGetData(indicatorId, countryId);
  const { data: loadedDataByFormula } = useGetDataByFormula(
    indicatorId,
    countryId,
    formula?.bitrixID ?? formula,
    selectedOrganization?.id ?? "all"
  );
  const { data: baseCompareData } = useGetCompareData(
    compareIds?.indicatorId,
    compareIds?.countryId
  );
  const { data: countries, isLoading: countriesIsLoading } =
    useGetCountriesByIndicator(indicatorId);
  const { changeTextField, changeBoolField, params } = useChartParams();
  const { min, max, yearRange, marks, changeSlider } =
    useSliderParams(loadedData);
  const { data: compareIndicators, isLoading: compareIndicatorsIsLoading } =
    useGetCompareIndicators(indicatorId);

  //   useEffect(() => {
  //     const accessCode = localStorage.getItem("accessCode");
  //     const activationTime = localStorage.getItem("activationTime");
  //     if (!accessCode || !activationTime) {
  //       navigate("/demo-access");
  //     }
  //   }, []);

  useCheckAccess();

  const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);

  const filteredData = useMemo(() => {
    const generalData = loadedDataByFormula ?? loadedData;
    if (!yearRange || !loadedData) return generalData;
    const [minYear, maxYear] = yearRange;
    const filteredByYear = generalData.filter(
      (item) => Number(item.year) >= minYear && Number(item.year) <= maxYear
    );
    return filteredByYear;
  }, [loadedData, yearRange, loadedDataByFormula]);

  const { data: indicator } = useGetOneIndicatorQuery(indicatorId, {
    skip: !indicatorId,
  });
  const { data: country } = useGetOneCountryQuery(countryId, {
    skip: !countryId,
  });
  // const { data: indicator } = useGetOneIndicator(indicatorId);
  // const { data: country } = useGetOneCountry(countryId);

  const indicatorType = useMemo(() => {
    if (indicator?.percentageValue?.value) {
      return "percentage";
    }
    if (indicator?.sumValue?.value) {
      return "sum";
    }
    if (indicator?.valuePerUnit?.value) {
      return "perUnit";
    }
  }, [indicator]);

  const [series1Name, setSeries1Name] = useState("series1");
  const [series2Name, setSeries2Name] = useState("series2");

  const compareData = useMemo(() => {
    if (!baseCompareData) return null;
    if (indicator?.bitrixID == compareIds?.indicatorId) return baseCompareData;

    const compareIndicator = compareIndicators.find(
      (item) => item.bitrixID === compareIds?.indicatorId
    );
    if (compareIndicator?.tableUnit === indicator?.tableUnit)
      return baseCompareData;

    const indicatorUnitRate = getUnitRate(indicator?.tableUnit);
    const compareIndicatorRate = getUnitRate(compareIndicator?.tableUnit);
    const rate = compareIndicatorRate / indicatorUnitRate;
    return baseCompareData?.map((item) => ({
      ...item,
      value: item.value * rate,
    }));
  }, [indicator, baseCompareData, compareIndicators, compareIds]);

  useEffect(() => {
    let name1 = "series1";
    let name2 = "series2";

    if (indicator?.bitrixID === compareIds?.indicatorId) {
      name1 = country?.name;
      name2 = selectedCountry?.label;
    } else if (country?.bitrixID === compareIds?.countryId) {
      name1 = indicator?.name;
      name2 = selectedIndicator?.label;
    } else if (indicator?.name && country?.name) {
      name1 = `${indicator?.name} | ${country?.name}`;
    }

    if (series1Name !== name1) {
      setSeries1Name(name1);
    }

    if (series2Name !== name2) {
      setSeries2Name(name2);
    }
  }, [indicator, country, compareIds]);

  // const { series1Name, series2Name } = useMemo(() => {
  //     if (!indicator || !country) {
  //         return { series1Name: 'series1', series2Name: 'series2' };
  //     }
  //     if (indicator?.bitrixID === compareIds?.indicatorId) {
  //         return { series1Name: country?.name, series2Name: selectedCountry?.label }
  //     }
  //     if (country?.bitrixID === compareIds?.countryId) {
  //         return { series1Name: indicator?.name, series2Name: selectedIndicator?.label }
  //     }
  //     return { series1Name: `${indicator?.name} | ${country?.name}`, series2Name: 'series2' };
  // }, [indicator, country, compareIds, selectedIndicator, selectedCountry])

  const { htmlDiagram, createChart } = useBaseTestChart(
    filteredData,
    compareData,
    params,
    chartName,
    series1Name,
    series2Name,
    indicator,
    formula,
    selectedOrganization
  );

  useLayoutEffect(() => {
    if (!filteredData) return;

    am5.addLicense("AM5C404071029");
    let root = am5.Root.new(chartName);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        ...(params.zoomEnable
          ? {
              wheelX: "panX",
              wheelY: "zoomX",
            }
          : {}),
        pinchZoomX: true,
        layout: root.verticalLayout,
      })
    );

    am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: "statbase_chart",
      dataSource: filteredData,
      pngOptions: {
        quality: 0.9,
      },
      jpgOptions: {
        quality: 0.9,
      },
    });

    if (params.showTitle) {
      chart.topAxesContainer.children.push(
        am5.Label.new(root, {
          text: `${getFormulaName(
            indicator,
            formula,
            selectedOrganization
          )} | ${getUnit(indicator, formula)}`,
          fontSize: 20,
          fontWeight: "400",
          x: am5.p50,
          centerX: am5.p50,
        })
      );
    }

    if (params.scrollbarEnable) {
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, { orientation: "horizontal" })
      );
    }

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // yAxis.children.unshift(am5.Label.new(root, {
    //     text: indicator?.unitRussian ?? '',
    //     textAlign: 'start',
    //     // y: am5.p50,
    //     // rotation: -90,
    //     // fontWeight: 'bold'
    // }));

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "year",
      })
    );
    xAxis.data.setAll(filteredData);

    const seriesObject =
      chartType === "line" ? am5xy.LineSeries : am5xy.ColumnSeries;

    // Create series
    let series1 = chart.series.push(
      seriesObject.new(root, {
        name: series1Name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "year",
        minBulletDistance: 50,
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          labelText: `{categoryX}: {valueY} ${getUnit(indicator, formula)}`,
        }),
      })
    );
    series1.data.setAll(filteredData);

    let series2 = undefined;
    if (compareData) {
      series2 = chart.series.push(
        seriesObject.new(root, {
          name: series2Name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "year",
          minBulletDistance: 50,
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            labelText: `{categoryX}: {valueY} ${getUnit(indicator, formula)}`,
          }),
        })
      );
      series2.data.setAll(compareData);
    }

    if (isColor(params.color)) {
      series1.set("fill", am5.color(params.color));
      series1.set("stroke", am5.color(params.color));
    }

    if (isColor(params.compareColor) && series2) {
      series2.set("fill", am5.color(params.compareColor));
      series2.set("stroke", am5.color(params.compareColor));
    }

    var yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: "0.7em",
      rotation: -60,
      centerY: am5.p50,
    });

    if (chartType === "columns") {
      series1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });
      series1.columns.template.set("interactive", true);

      if (series2) {
        series2.columns.template.setAll({
          cornerRadiusTL: 0,
          cornerRadiusTR: 0,
        });
        series2.columns.template.set("interactive", true);
      }

      if (isColor(params.hoverColor)) {
        series1.columns.template.states.create("hover", {
          fill: am5.color(params.hoverColor),
          stroke: am5.color(params.hoverColor),
        });
        if (series2) {
          series2.columns.template.states.create("hover", {
            fill: am5.color(params.hoverColor),
            stroke: am5.color(params.hoverColor),
          });
        }
      }
    }

    if (chartType === "line") {
      if (isColor(params.color)) {
        series1.set("fill", am5.color(params.color));
        series1.set("stroke", am5.color(params.color));
      }

      series1.bullets.push(function () {
        var bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: series1.get("fill"),
        });
        return am5.Bullet.new(root, {
          sprite: bulletCircle,
        });
      });

      if (series2) {
        if (isColor(params.compareColor) && series2) {
          series2.set("fill", am5.color(params.compareColor));
          series2.set("stroke", am5.color(params.compareColor));
        }

        series2.bullets.push(function () {
          var bulletCircle = am5.Circle.new(root, {
            radius: 5,
            fill: series2.get("fill"),
          });
          return am5.Bullet.new(root, {
            sprite: bulletCircle,
          });
        });
      }
    }

    if (params.showLegend) {
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
        })
      );
      legend.data.setAll(chart.series.values);
    }

    series1.appear(500);

    if (series2) {
      series2.appear(500);
    }

    // const jsonSerializer = am5plugins_json.Serializer.new(root, {});
    // const jsonData = jsonSerializer.serialize(data, 0, true);
    // console.log("🚀 ~ useLayoutEffect ~ jsonData:", JSON.stringify(jsonData))
    // series1.set("fill", am5.color('#67b7dc'));

    // let series2 = chart.series.push(
    //     am5xy.ColumnSeries.new(root, {
    //         name: "Series",
    //         xAxis: xAxis,
    //         yAxis: yAxis,
    //         valueYField: "value2",
    //         categoryXField: "category"
    //     })
    // );
    // series2.data.setAll(data);

    // Add legend
    // let legend = chart.children.push(am5.Legend.new(root, {}));
    // legend.data.setAll(chart.series.values);

    // Add cursor
    // chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [
    filteredData,
    params,
    compareData,
    indicator,
    series1Name,
    series2Name,
    formula,
    selectedOrganization,
    chartType,
  ]);

  const compareIndicatorHandler = (_, value) => {
    setSelectedIndicator(value);
    setSelectedCountry(null);
  };

  const compareCountryHandler = (_, value) => {
    setSelectedIndicator(null);
    setSelectedCountry(value);
  };

  const onClickCompare = useCallback(() => {
    const compareIndicatorId = selectedIndicator?.id ?? indicatorId;
    const compareCountryId = selectedCountry?.id ?? countryId;
    setCompareIds({
      indicatorId: compareIndicatorId,
      countryId: compareCountryId,
    });
    setFormula(0);
    // setCompareCountryId(selectedCountry);
  }, [indicatorId, countryId, selectedIndicator, selectedCountry]);

  const resetCompare = useCallback(() => {
    setSelectedCountry(null);
    setSelectedIndicator(null);
    setCompareIds({ indicatorId: null, countryId: null });
    // setCompareCountryId(null);
  }, [setSelectedCountry, setSelectedIndicator, setCompareIds]);

  useEffect(() => {
    setFormula(null);
    resetCompare();
  }, [indicatorId, countryId]);

  const indicatorsCompareDictionary = useMemo(() => {
    if (!compareIndicators) return [];
    return compareIndicators?.map((item) => ({
      id: item.bitrixID,
      label: item.name,
    }));
  }, [compareIndicators, indicator]);

  const countryDictionary = useMemo(() => {
    if (!countries) return [];
    return countries?.map((item) => ({ id: item.bitrixID, label: item.name }));
  }, [countries]);

  return (
    <>
      <Header />
      {!filteredData && <LinearProgress />}
      {filteredData && (
        <Grid container sx={headerGridSx}>
          <ChartTypeLinks type={vizualizationType} />
          {/* <ChooseIndicator beforeRedirectHandler={() => setFormula(null)} /> */}
          <CountryAndIndicatorChoose
            indicator={indicator}
            country={country}
            beforeRedirectHandler={() => setFormula(null)}
          />
          {!indicator || !country ? (
            <LinearProgress />
          ) : (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                mb="10px"
              >
                <Grid container sx={headerTextSx}>
                  {`${indicator?.name} | ${country?.name}`}
                  {indicator?.description && (
                    <NoMaxWidthTooltip
                      title={
                        <Grid sx={{ fontSize: "14px" }}>
                          {indicator?.description}
                        </Grid>
                      }
                    >
                      <IconButton>
                        <InfoRoundedIcon color="info" />
                      </IconButton>
                    </NoMaxWidthTooltip>
                  )}
                </Grid>
                <Grid width="auto" container gap="5px">
                  {indicator?.sources?.map((item) => (
                    <Chip label={item?.name} />
                  ))}
                </Grid>
              </Grid>
              {indicator?.text && (
                <Grid container sx={headerDescriptionTextSx}>
                  {indicator?.text}
                </Grid>
              )}
            </>
          )}
          <Grid container sx={mainGridSx}>
            <Grid container sx={paramsSx}>
              <Grid container sx={paramsTitleSx}>
                Настройки графика
              </Grid>
              <Grid container gap="10px" alignItems="baseline">
                <TextField
                  sx={paramsTextField}
                  label="Цвет"
                  size="small"
                  value={params.color}
                  onChange={(e) => changeTextField("color", e)}
                />
                {
                  compareData && (
                    // <Grid container gap='10px' alignItems='baseline'>
                    <TextField
                      sx={paramsTextField}
                      label="Цвет сравнения"
                      size="small"
                      value={params.compareColor}
                      onChange={(e) => changeTextField("compareColor", e)}
                    />
                  )
                  // </Grid>
                }
                <TextField
                  sx={paramsTextField}
                  label="Цвет при наведении"
                  size="small"
                  value={params.hoverColor}
                  onChange={(e) => changeTextField("hoverColor", e)}
                />
              </Grid>
              {/* <Grid container gap='10px' alignItems='baseline'>
                        </Grid> */}
              <Grid container gap="10px" alignItems="center">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={params.zoomEnable}
                  onClick={() => changeBoolField("zoomEnable")}
                />
                Включить масштабирование по оси X
              </Grid>
              <Grid container gap="10px" alignItems="center">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={params.scrollbarEnable}
                  onClick={() => changeBoolField("scrollbarEnable")}
                />
                Отображать полосу прокрутки
              </Grid>
              <Grid container gap="10px" alignItems="center">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={params.showTitle}
                  onClick={() => changeBoolField("showTitle")}
                />
                Отображать заголовок
              </Grid>
              <Grid container gap="10px" alignItems="center">
                <Checkbox
                  sx={{ padding: 0 }}
                  checked={params.showLegend}
                  onClick={() => changeBoolField("showLegend")}
                />
                Отображать легенду
              </Grid>
              <Grid container gap="10px" alignItems="baseline">
                <TextField
                  sx={paramsTextField}
                  label="Ширина"
                  size="small"
                  value={params.width}
                  onChange={(e) => changeTextField("width", e)}
                />
                <TextField
                  sx={paramsTextField}
                  label="Высота"
                  size="small"
                  value={params.height}
                  onChange={(e) => changeTextField("height", e)}
                />
              </Grid>
              {/* <Grid container gap='10px' alignItems='baseline'>
                        </Grid> */}
              {!!yearRange && (
                <Grid
                  container
                  width="300px"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="5px"
                  paddingLeft="10px"
                >
                  <Grid>Диапазон</Grid>
                  <Slider
                    // sx={{ width: '400px' }}
                    min={min}
                    max={max}
                    value={yearRange}
                    onChange={changeSlider}
                    marks={marks}
                    valueLabelDisplay="auto"
                  />
                </Grid>
              )}
              {/* <Grid width={'300px'}><Slider min={1997} max={2024} value={[2014, 2020]} /></Grid> */}
            </Grid>
            <Grid>
              <Divider orientation="vertical" />
            </Grid>
            <Grid container sx={compareSx}>
              <Grid container sx={compareTextSx}>
                Сравнить
              </Grid>
              {(countriesIsLoading || compareIndicatorsIsLoading) && (
                <Grid width="100%">
                  <LinearProgress />
                </Grid>
              )}
              <Autocomplete
                size="small"
                onChange={compareIndicatorHandler}
                options={indicatorsCompareDictionary}
                fullWidth
                value={selectedIndicator}
                disabled={
                  !indicatorsCompareDictionary.length ||
                  compareIndicatorsIsLoading
                }
                renderInput={(params) => (
                  <TextField {...params} label="Индикаторы для сравнения" />
                )}
              />
              <Autocomplete
                size="small"
                onChange={compareCountryHandler}
                options={countryDictionary}
                fullWidth
                value={selectedCountry}
                disabled={!countryDictionary.length || countriesIsLoading}
                renderInput={(params) => (
                  <TextField {...params} label="Страны для сравнения" />
                )}
              />
              <Button
                fullWidth
                variant="outlined"
                color="info"
                disabled={!selectedIndicator && !selectedCountry}
                onClick={onClickCompare}
              >
                Сравнить
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="info"
                disabled={!compareIds?.indicatorId || !compareIds?.countryId}
                onClick={resetCompare}
              >
                Сбросить
              </Button>
            </Grid>
          </Grid>
          <ChartTypeSelector
            chartType={chartType}
            setChartType={setChartType}
          />
          <FormulaSelector
            formulaId={formula?.bitrixID ?? formula}
            indicatorId={indicatorId}
            countryId={countryId}
            indicatorType={indicatorType}
            indicator={indicator}
            organizationId={selectedOrganization?.id}
            organization={selectedOrganization}
            disabled={!!compareIds?.indicatorId && !!compareIds?.countryId}
            onChange={(formula) => {
              setFormula(formula);
              resetCompare();
            }}
            onChangeOrganization={setSelectedOrganization}
          />
          {/* <Divider orientation="horizontal" /> */}
          {/* <VisualizationLinks type={vizualizationType} /> */}
          <Grid width={"100%"} container gap={"10px"}>
            <div
              id={chartName}
              style={{ width: params.width, height: params.height }}
            ></div>
            <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
