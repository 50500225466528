import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCountriesQuery, useGetIndicatorsByCountryQuery } from "../../store/api/dictionaries.api";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
}

const autocompleteSx = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const CountryAndIndicatorChoose = ({ indicator, country, beforeRedirectHandler }) => {
    const navigate = useNavigate();
    const [selectedIndicator, setSelectedIndicator] = useState(undefined);
    const [selectedCountry, setSelectedCountry] = useState(undefined);

    const { data: countries, isFetching: countriesIsLoading } = useGetCountriesQuery();
    const { data: indicators, isFetching: indicatorsIsLoading } = useGetIndicatorsByCountryQuery(selectedCountry?.id ?? country?.bitrixID, {
        skip: !country?.bitrixID && !selectedCountry?.id
    });

    const indicatorDictionary = useMemo(() => {
        if (!indicators) return [];
        return indicators?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [indicators]);

    const countryDictionary = useMemo(() => {
        if (!countries) return [];
        return countries?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [countries]);

    const redirect = useCallback(() => {
        if (!selectedIndicator?.id && !selectedCountry?.id) return;
        const indicatorId = selectedIndicator?.id ?? indicator?.bitrixID;
        const countryId = selectedCountry?.id ?? country?.bitrixID;
        beforeRedirectHandler?.()
        navigate(`/indicator/${indicatorId}/country/${countryId}`);
    }, [beforeRedirectHandler, indicator, country, selectedIndicator, selectedCountry]);

    const hasLoader = useMemo(() => {
        if (!indicator || !country) return true;
        if (countriesIsLoading || indicatorsIsLoading) return true;
        return false;
    }, [indicator, country, countriesIsLoading, indicatorsIsLoading]);

    const hasData = useMemo(() => {
        if (!indicator || !country) return false;
        return true;
    }, [indicator, country]);

    return (
        <Grid container sx={gridSx}>
            {hasLoader && <Grid width='100%'><LinearProgress /></Grid>}
            {hasData && <>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => setSelectedCountry(value)}
                    options={countryDictionary}
                    value={selectedCountry}
                    disabled={countriesIsLoading}
                    defaultValue={{ id: country.bitrixID, label: country.name }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    sx={autocompleteSx}
                    renderInput={(params) => <TextField {...params} label="Страна" />}
                />
                <Autocomplete
                    size="small"
                    onChange={(_, value) => setSelectedIndicator(value)}
                    options={indicatorDictionary}
                    value={selectedIndicator}
                    disabled={indicatorsIsLoading}
                    defaultValue={{ id: indicator.bitrixID, label: indicator.name }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    sx={autocompleteSx}
                    renderInput={(params) => <TextField {...params} label="Индикатор" />}
                />
                <Button
                    variant="outlined"
                    color="info"
                    disabled={!selectedIndicator && !selectedCountry}
                    onClick={redirect}
                    sx={buttonSx}
                >
                    Перейти
                </Button>
            </>}
        </Grid>
    );
}
