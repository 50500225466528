import { Chip, Divider, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";



export const ChartTypeLinks = ({ type }) => {
    const { indicatorId, sDataIndicatorId } = useParams();
    const navigate = useNavigate();

    const pageType = useMemo(() => {
        if (type == 'column') {
            return 'countries';
        }
        if (['treemap', 'piechart', 'race', 'columncountries', 'map', 'line'].includes(type)) {
            return 'worldAndRegions';
        }
        if (type == 'sdata') {
            return 'sdata';
        }
    }, [type]);

    const onClick = useCallback((clickType) => {
        const baseIndicator = 12276;
        const baseSDataIndicator = 505454;

        if (pageType === clickType) return;
        if (clickType === 'countries') {
            navigate(`/indicator/${indicatorId ?? baseIndicator}/country/6071`);
        }
        if (clickType == 'worldAndRegions') {
            navigate(`/indicator/${indicatorId ?? baseIndicator}/treemap`);
        }
        if (clickType === 'sdata') {
            navigate(`/indicator/${baseSDataIndicator}/country/6071/sdata`);
        }
    }, [indicatorId, pageType]);

    return (
        <Grid container flexDirection='row' alignItems='flex-start' gap='15px'>
            <Grid container gap='5px'>
                <Chip
                    key={`countries`}
                    color={(pageType === 'countries') ? 'primary' : 'default'}
                    label='По странам'
                    onClick={() => onClick('countries')}
                />
                <Chip
                    key={`sdata`}
                    color={(pageType === 'sdata') ? 'primary' : 'default'}
                    label='По странам с аналитикой'
                    onClick={() => onClick('sdata')}
                />
                <Chip
                    key={`worldAndRegions`}
                    color={(pageType === 'worldAndRegions') ? 'primary' : 'default'}
                    label='По миру и регионам'
                    onClick={() => onClick('worldAndRegions')}
                />
            </Grid>
        </Grid >
    );
}
