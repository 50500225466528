import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './api.const';

export const generalApi = createApi({
    reducerPath: 'generalApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getDemoAccess: builder.query({
            query: (code) => `demo_access.php?code=${code}`,
        }),
    }),
})

export const {
    useLazyGetDemoAccessQuery,
    useGetDemoAccessQuery
} = generalApi;