import { Autocomplete, Button, Divider, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetIndicatorsQuery, useGetSDataIndicatorsQuery } from "../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import { addAnalyticsObject } from "../../store/reducer/sDataSlice";

const gridSx = {
    display: 'flex',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px 0px 10px 5px',
    // gap: '10px',
    width: '25%',
    height: '100%',
    flexDirection: 'column'
}

const listSx = {
    display: 'flex',
    // padding: '10px',
    flexDirection: 'column',
    overflowY: 'scroll'
}

const itemSx = {
    justifyContent: 'flex-start',
    textTransform: 'unset',
    textAlign: 'left',
    // whiteSpace: 'nowrap',

}


export const AnalyticsObjectsTreeGrid = ({ analyticsObjects, groupDisabled, subIndex, hideElements = false }) => {
    const dispatch = useDispatch();
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);

    const onClick = useCallback((analyticsObject) => {
        const { childrens, ...other } = analyticsObject;
        dispatch(addAnalyticsObject(other));
    }, [dispatch]);

    const sortedAnalyticsObjects = useMemo(() => {
        return [...analyticsObjects]?.sort((a, b) => Number(a.sort) - Number(b.sort));
    }, [analyticsObjects]);


    return (
        <Grid ml='10px'>
            {sortedAnalyticsObjects?.map((item, index) => {
                const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);
                const isSelect = analyticsObjectKeys.includes(item.bitrixID);
                if (item?.childrens?.length > 0) {
                    return <>
                        <Button
                            key={`analyticsObjectsTreeGrid_${subIndex}_${index}_button`}
                            variant="text"
                            fullWidth
                            sx={itemSx}
                            color="inherit"
                            onClick={() => onClick(item)}
                            disabled={isSelect || groupDisabled}
                        >
                            {item?.name}
                        </Button>
                        <Divider />
                        <AnalyticsObjectsTreeGrid
                            key={`analyticsObjectsTreeGrid_${subIndex}_${index}_grid`}
                            subIndex={`${subIndex}_${index}`}
                            analyticsObjects={item?.childrens}
                            groupDisabled={isSelect}
                            hideElements={hideElements}
                        />
                    </>

                }

                if (hideElements) return null;
                return <>
                    <Button
                        key={`analyticsObjectsTreeGrid_${subIndex}_${index}_button2`}
                        variant="text"
                        fullWidth
                        sx={itemSx}
                        color="inherit"
                        onClick={() => onClick(item)}
                        disabled={isSelect || groupDisabled}
                    >
                        {item?.name}
                    </Button>
                    <Divider />
                </>
            })}
        </Grid>
    );
}
