import { Checkbox, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { mainGridSx, paramsSx, paramsTextField, paramsTitleSx } from "./mapGraph.styles";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export const MapGraphParams = ({ params, actions }) => {

    const { changeTextField, changeBoolField } = actions;

    return (
        <Grid container sx={mainGridSx}>
            <Grid container sx={paramsSx}>
                <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Grid container sx={paramsTitleSx}>Настройки графика</Grid>
                    <Grid>
                        <Tooltip title='Сбросить'>
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip >
                    </Grid>
                </Grid>
                <Grid container gap='10px' alignItems='baseline'>
                    <TextField sx={paramsTextField} label='Ширина' size="small" value={params.width} onChange={(e) => changeTextField('width', e)} />
                    <TextField sx={paramsTextField} label='Высота' size="small" value={params.height} onChange={(e) => changeTextField('height', e)} />
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showTitle} onClick={() => changeBoolField('showTitle')} />
                    Отображать заголовок
                </Grid>
            </Grid>
        </Grid>
    );
}

