import {
    Autocomplete,
    Divider,
    Grid,
    LinearProgress,
    TextField,
    Tooltip,
    tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetCountriesByIndicatorQuery, useGetCountriesQuery, useGetIndicatorsForCompareQuery } from "../../store/api/dictionaries.api";
import { setCompareCountry, setCompareIndicator } from "../../store/reducer/sDataSlice";

const gridSx = {
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    padding: "10px",
    gap: "10px",
};

const autocompleteSx = {
    // width: "450px",
    "@media (max-width: 600px)": {
        width: "100%",
    },
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
    },
});

const paramsTitleSx = {
    fontSize: "32px",
    fontWeight: "600",
    mb: "5px",
    width: "auto",
    "@media (max-width: 600px)": {
        fontSize: "24px",
        justifyContent: "center",
    },
};

export const SDataCompare = ({ isNotCompare }) => {
    const dispatch = useDispatch();
    const { sDataIndicatorId } = useParams();
    const { data: countries, isFetching: countriesIsLoading } = useGetCountriesByIndicatorQuery(sDataIndicatorId, { skip: isNotCompare });
    const { data: indicators, isFetching: indicatorsIsLoading } = useGetIndicatorsForCompareQuery(sDataIndicatorId);

    const compareCountry = useSelector((state) => state.sDataSlice.compareCountry);
    const compareIndicator = useSelector((state) => state.sDataSlice.compareIndicator);
    const currentFormula = useSelector((state) => state.sDataSlice.formula);

    const countriesCompareDictionary = useMemo(
        () =>
            countries
                ?.filter((item) => !item.isOutputDatasetsOnly)
                ?.map((item) => ({
                    id: item.bitrixID,
                    label: item.name,
                })),
        [countries]
    );

    const indicatorsCompareDictionary = useMemo(
        () =>
            indicators?.map((item) => ({
                id: item.bitrixID,
                label: item.name,
            })),
        [indicators]
    );

    const onChangeCountry = useCallback(
        (value) => {
            dispatch(setCompareIndicator(null));
            dispatch(setCompareCountry(value));
        },
        [dispatch]
    );

    const onChangeIndicator = useCallback(
        (value) => {
            dispatch(setCompareCountry(null));
            dispatch(setCompareIndicator(value));
        },
        [dispatch]
    );

    const compareBlockNotVisible = useMemo(() => {
        const noIndicatorData = !indicatorsCompareDictionary || !indicatorsCompareDictionary?.length;
        if (noIndicatorData && isNotCompare) return true;
        return false
    }, [isNotCompare, indicatorsCompareDictionary])

    if (compareBlockNotVisible) {
        return null;
    }

    return (
        <>
            <Grid>
                <Divider orientation="vertical" />
            </Grid>
            <Grid display='flex' flexGrow={1} flexDirection="column" gap="5px" width='45%'>
                {(countriesIsLoading || indicatorsIsLoading) && (
                    <Grid width="100%">
                        <LinearProgress />
                    </Grid>
                )}
                <Grid container sx={paramsTitleSx}>
                    Сравнить
                </Grid>
                {/* <Grid container>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => onChangeRegion(value)}
                    options={regionDictionary}
                    sx={autocompleteSx}
                    value={compareRegion}
                    disabled={!compareRegionAvailable}
                    renderInput={(params) => <TextField {...params} label="Регион" />}
                />
                {Boolean(organizationCountriesText) && (
                    <>
                        {isLoadingOrganizationCountries ? (
                            <CircularProgress size="26px" />
                        ) : (
                            <NoMaxWidthTooltip
                                title={
                                    <Grid sx={{ fontSize: "14px" }}>
                                        {organizationCountriesText}
                                    </Grid>
                                }
                            >
                                <IconButton>
                                    <InfoRoundedIcon color="info" />
                                </IconButton>
                            </NoMaxWidthTooltip>
                        )}
                    </>
                )}
            </Grid> */}
                {!isNotCompare && <Autocomplete
                    size="small"
                    fullWidth
                    onChange={(_, value) => onChangeCountry(value)}
                    options={countriesCompareDictionary ?? []}
                    sx={autocompleteSx}
                    value={compareCountry}
                    disabled={countriesIsLoading || Boolean(currentFormula)}
                    renderInput={(params) => <TextField {...params} label="Страна" />}
                />}
                {indicatorsCompareDictionary?.length > 0 &&
                    <Autocomplete
                        size="small"
                        fullWidth
                        onChange={(_, value) => onChangeIndicator(value)}
                        options={indicatorsCompareDictionary}
                        sx={autocompleteSx}
                        value={compareIndicator}
                        disabled={indicatorsIsLoading || Boolean(currentFormula)}
                        renderInput={(params) => <TextField {...params} label="Индикатор" />}
                    />
                }
            </Grid>
        </>
    );
};
