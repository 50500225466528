export const sDataCategoriesDefaultParams = {
    width: "100%",
    height: "600px",
    showLegend: true,
    zoomEnable: true,
    scrollbarEnable: true,
    showTitle: true,
    startYAxisFromZero: false,
    hoverColor: '#297373',
    hideYAxis: false,
    sortByValue: false,
    sortDesc: false,
};
