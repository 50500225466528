import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useMemo } from "react";
import { useGetOrganizations } from "./chooseRegion.hooks";
import { useGetOrganizationsQuery } from "../../store/api/dictionaries.api";
import { useDispatch } from "react-redux";
import { setRegion, setYear } from "../../store/reducer/dataSlice";
import {
  useGetCompareIndicatorsQuery,
  useGetOrganizationCountriesQuery,
} from "../../store/api/info.api";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const gridSx = {
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "10px",
  gap: "10px",
};

const autocompleteSx = {
  width: "300px",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const ChooseRegion = ({
  selectedRegion,
  selectedYear,
  onChangeRegion,
  onChangeYear,
  yearFrom,
  yearTo,
  defaultYear,
  isOutputLastYearData,
  type,
}) => {
  const { data: organizations } = useGetOrganizationsQuery();
  const {
    data: organizationCountries,
    isFetching: isLoadingOrganizationCountries,
  } = useGetOrganizationCountriesQuery(selectedRegion?.id, {
    skip: !selectedRegion?.id,
  });
  const dispatch = useDispatch();

  const hasPeriod = Boolean(yearFrom) && Boolean(yearTo);

  const autocompleteHandler = useCallback(
    (name, value) => {
      if (name === "region") {
        dispatch(setRegion(value));
        onChangeRegion?.(value);
      }
      if (name === "year") {
        dispatch(setYear(value));
        onChangeYear?.(value);
      }
    },
    [onChangeRegion, onChangeYear]
  );

  const regionDictionary = useMemo(() => {
    if (!organizations) return [];
    const filtered = organizations
      ?.filter((item) => Boolean(item.agrType))
      ?.sort((a, b) => a.sort - b.sort);
    return filtered?.map((item) => ({ id: item.bitrixId, label: item.name }));
  }, [organizations]);

  const yearDictionary = useMemo(() => {
    if (!yearFrom || !yearTo) return [];
    let dictionary = [];
    if (!isOutputLastYearData) {
      dictionary.push({ id: 0, label: "Последний доступный год" });
    }
    for (let i = yearTo; i >= yearFrom; i--) {
      dictionary.push({ id: i, label: i });
    }
    return dictionary;
  }, [isOutputLastYearData, yearFrom, yearTo]);

  const showYearSelect = useMemo(() => {
    return ["treemap", "piechart", "map"].includes(type);
  }, [type]);

  const organizationCountriesText = useMemo(() => {
    if (!selectedRegion) return null;
    const names = organizationCountries?.map((item) => item?.name);
    return `Страны: ${names?.join(", ")}`;
  }, [selectedRegion, organizationCountries]);

  return (
    <Grid container sx={gridSx}>
      {!organizations && (
        <Grid width="100%">
          <LinearProgress />
        </Grid>
      )}
      {!!organizations && (
        <>
          <Autocomplete
            size="small"
            onChange={(_, value) => autocompleteHandler("region", value)}
            options={regionDictionary}
            sx={autocompleteSx}
            value={selectedRegion}
            renderInput={(params) => <TextField {...params} label="Регион" />}
          />
        </>
      )}
      {Boolean(organizationCountriesText) && (
        <>
          {isLoadingOrganizationCountries ? (
            <CircularProgress size="26px" />
          ) : (
            <NoMaxWidthTooltip
              title={
                <Grid sx={{ fontSize: "14px" }}>
                  {organizationCountriesText}
                </Grid>
              }
            >
              <IconButton>
                <InfoRoundedIcon color="info" />
              </IconButton>
            </NoMaxWidthTooltip>
          )}
        </>
      )}
      {showYearSelect && defaultYear && (
        <Autocomplete
          size="small"
          onChange={(_, value) => autocompleteHandler("year", value)}
          options={yearDictionary}
          disabled={!hasPeriod}
          sx={autocompleteSx}
          value={selectedYear ? selectedYear : undefined}
          defaultValue={{
            id: isOutputLastYearData ? defaultYear : 0,
            label: isOutputLastYearData
              ? defaultYear
              : "Последний доступный год",
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} label="Год" />}
        />
      )}
    </Grid>
  );
};
