import { Chip, Grid } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAnalyticsObject } from "../../store/reducer/sDataSlice";

const gridSx = {
    // border: '1px solid #e0e0e0',
    // borderRadius: '10px',
    // padding: '10px',
    gap: '10px',
    width: '100%',
    // maxHeight: '250px',
    // flexDirection: 'row',
    // overflowY: 'scroll'
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

export const AnalyticsObjectsView = ({ isExcluded }) => {
    const dispatch = useDispatch();
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);

    const handleDelete = useCallback((analyticsObject) => {
        dispatch(deleteAnalyticsObject(analyticsObject?.bitrixID));
    }, [dispatch]);

    return (
        <Grid container sx={gridSx}>
            {selectedAnalyticsObjects?.map((item) => {
                return <Chip
                    label={item?.name}
                    variant="outlined"
                    color={isExcluded ? "error" : "success"}
                    size="small"
                    onDelete={() => handleDelete(item)}
                />
            })}
        </Grid>
    );
}
