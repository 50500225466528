
export const prepareRaceData = (data, yearStart, yearCount, maxItems) => {
    if (!data || !yearStart || !yearCount || !maxItems) return {};
    let yearArray = [];
    for (let i = yearStart + 1; i <= yearStart + yearCount; i++) {
        yearArray.push(i);
    }
    let countriesSet = new Set();
    const dataObject = yearArray.reduce((acc, currentYear) => {
        const itemsByYear = data?.filter((item) => item.year === currentYear)
            .sort((a, b) => b.value - a.value)
            .slice(0, maxItems);

        itemsByYear.forEach(element => {
            countriesSet.add(element.name);
        });

        const itemsObject = itemsByYear.reduce((acc, item) => {
            return { ...acc, [item.name]: item.value }
        }, {});
        return { ...acc, [currentYear]: itemsObject }
    }, {});

    const resultRaceObject = yearArray.reduce((acc, currentYear) => {
        let object = {};
        countriesSet.forEach(item => {
            object[item] = dataObject[currentYear][item] ?? 0;
        });

        return { ...acc, [currentYear]: object }
    }, {});


    return resultRaceObject;
}

export const prepareRaceDataWithSlider = (data, yearRange, maxItems) => {
    if (!data || !yearRange || !maxItems) return {};
    const [yearFrom, yearTo] = yearRange;

    let yearArray = [];
    for (let i = yearFrom; i <= yearTo; i++) {
        yearArray.push(i);
    }
    let countriesSet = new Set();
    const dataObject = yearArray.reduce((acc, currentYear) => {
        const itemsByYear = data?.filter((item) => item.year === currentYear)
            .sort((a, b) => b.value - a.value)
            .slice(0, maxItems);

        itemsByYear.forEach(element => {
            countriesSet.add(element.name);
        });

        const itemsObject = itemsByYear.reduce((acc, item) => {
            return { ...acc, [item.name]: item.value }
        }, {});
        return { ...acc, [currentYear]: itemsObject }
    }, {});

    let firstData = {};
    countriesSet.forEach(item => {
        firstData[item] = 0;
    });

    const resultRaceObject = yearArray.reduce((acc, currentYear) => {
        let object = {};
        countriesSet.forEach(item => {
            object[item] = dataObject[currentYear][item] ?? 0;
        });

        return { ...acc, [currentYear]: object }
    }, {});

    return { [Number(yearFrom - 1)]: firstData, ...resultRaceObject };
}