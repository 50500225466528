import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api.const";

export const infoApi = createApi({
  reducerPath: "infoApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getOneIndicator: builder.query({
      query: (indicatorId) => `get_one_indicator.php?indicator=${indicatorId}`,
    }),
    getOneCountry: builder.query({
      query: (countryId) => `get_one_country.php?country=${countryId}`,
    }),
    getOrganizationCountries: builder.query({
      query: (organizationId) =>
        `get_organization_countries.php?organization=${organizationId}`,
    }),
    getCompareIndicators: builder.query({
      query: (indicatorId) =>
        `get_compare_indicators.php?indicator=${indicatorId}`,
    }),
    getIndicatorFormulas: builder.query({
      query: (indicatorId) =>
        `get_formulas_indicator.php?indicator=${indicatorId}`,
    }),
  }),
});

export const {
  useGetOneIndicatorQuery,
  useGetOneCountryQuery,
  useGetOrganizationCountriesQuery,
  useGetCompareIndicatorsQuery,
  useGetIndicatorFormulasQuery,
} = infoApi;
