
export const prepareMapData = (data, params) => {
    if (!data) return [];
    const unit = params.unit;
    const chartData = data
        ?.map((item) => ({
            id: item.countryCode.toUpperCase(),
            name: item.name,
            value: item.value,
            unit: unit,
            tooltipValue: `${item.name} | ${item.year}`,
        }))
    // ?.sort((a, b) => {
    //     if (params.sortDesc) return a.value - b.value
    //     return b.value - a.value
    // })
    // ?.slice(0, params.itemsCount) ?? [];

    return chartData;
}