
export const checkResetChartTypes = (clickType, chartType) => {
    const presets = [
        ['columns', 'line'],
        ['categories', 'bar'],
        ['treemap', 'piechart', 'racechart'],
    ];

    const isResetAnalyticsObject = presets.some((preset) => {
        return (preset.includes(clickType) && !preset.includes(chartType))
            || (!preset.includes(clickType) && preset.includes(chartType))
    });

    return isResetAnalyticsObject;
}