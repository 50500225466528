import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { prepareRaceData, prepareRaceDataWithSlider } from "./raceGraph.utils";

export const useGetDataByFormula = (indicatorId, year, type) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&year=${year}&frmreq=0&sproc=sum&regval=all`;
    }, [indicatorId, year, type]);

    useEffect(() => {
        if (!indicatorId || !year || !type) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, year, type]);

    return { data, isLoading }
}

export const useGetDataByYear = (indicatorId, selectedRegion) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        if (selectedRegion) {
            const organizationId = selectedRegion.id;
            return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&fullDataset=1&organization=${organizationId}`;
        }
        return `${basePath}/get_data_by_year.php?indicator=${indicatorId}&fullDataset=1`;
    }, [indicatorId, selectedRegion]);

    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, selectedRegion]);

    return { data, isLoading }
}

export const useGetOneIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useRaceGraphChart = (chartName, dataByYear, params, sliderParams, title) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!dataByYear) return;
        const [yearFrom, yearTo] = sliderParams.yearRange;
        const count = Number(yearTo - yearFrom) + 2;
        // const count = Number(params.yearCount);
        const unit = params.unit;
        const maxItems = Number(params.itemsCount);
        // const chartData = prepareRaceData(dataByYear?.data, dataByYear?.info?.yearTo - count, count, maxItems);
        const chartData = prepareRaceDataWithSlider(dataByYear?.data, sliderParams.yearRange, maxItems);
        const endYear = dataByYear?.info?.yearTo;

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tconst count = ${count};\r\n`);
        strArray.push(`\tconst endYear = ${endYear};\r\n`);
        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.numberFormatter.setAll({
            numberFormat: "#a",

            bigNumberPrefixes: [
                { number: 1e6, suffix: "млн." },
                { number: 1e9, suffix: "млрд." }
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: []
        });\r\n`);
        strArray.push(`\tvar stepDuration = ${params?.stepDuration ? Number(params?.stepDuration) * 1000 : 2000};\r\n`);
        strArray.push(`\troot.setThemes([am5themes_Animated.new(root)]);\r\n`);
        strArray.push(`\tvar chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0
        }));\r\n`);

        if (params.showTitle) {
            strArray.push(`\tchart.topAxesContainer.children.push(am5.Label.new(root, {
                text: '${title}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50
            }));\r\n`);
        }

        strArray.push(`\tchart.zoomOutButton.set("forceHidden", true);\r\n`);
        strArray.push(`\tvar yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });\r\n`);
        strArray.push(`\tyRenderer.grid.template.set("visible", false);\r\n`);
        strArray.push(`\t var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: "network",
            renderer: yRenderer
        }));\r\n`);
        strArray.push(`\tvar xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0,
            min: 0,
            strictMinMax: true,
            extraMax: 0.1,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));\r\n`);
        strArray.push(`\txAxis.set("interpolationDuration", stepDuration / 10);\r\n`);
        strArray.push(`\txAxis.set("interpolationEasing", am5.ease.linear);\r\n`);
        strArray.push(`\tvar series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "value",
            categoryYField: "network"
        }));\r\n`);
        strArray.push(`\tseries.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });\r\n`);
        strArray.push(`\tseries.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });\r\n`);
        strArray.push(`\tseries.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });\r\n`);
        strArray.push(`\tseries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Label.new(root, {
                    text: "{valueXWorking.formatNumber('#.# a')} ${unit}",
                    ${params?.valuesInsideGraph ?
                `fill: root.interfaceColors.get("alternativeText"),
                    centerX: am5.p100,` :
                ''}
                    centerY: am5.p50,
                    populateText: true
                })
            });
        });\r\n`);
        strArray.push(`\tvar label = chart.plotContainer.children.push(am5.Label.new(root, {
            text: "2014",
            fontSize: "8em",
            opacity: 0.2,
            x: am5.p100,
            y: am5.p100,
            centerY: am5.p100,
            centerX: am5.p100
        }));\r\n`);
        strArray.push(`\tfunction getSeriesItem(category) {
            for (var i = 0; i < series.dataItems.length; i++) {
                var dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") == category) {
                    return dataItem;
                }
            }
        }\r\n`);
        strArray.push(`\tfunction sortCategoryAxis() {
            // sort by value
            series.dataItems.sort(function (x, y) {
                return y.get("valueX") - x.get("valueX"); // descending
                //return x.get("valueX") - y.get("valueX"); // ascending
            });

            // go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                var seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    var index = series.dataItems.indexOf(seriesDataItem);
                    // calculate delta position
                    var deltaPosition =
                        (index - dataItem.get("index", 0)) / series.dataItems.length;
                    // set index to be the same as series data item index
                    if (dataItem.get("index") != index) {
                        dataItem.set("index", index);
                        // set deltaPosition instanlty
                        dataItem.set("deltaPosition", -deltaPosition);
                        // animate delta position to 0
                        dataItem.animate({
                            key: "deltaPosition",
                            to: 0,
                            duration: stepDuration / 2,
                            easing: am5.ease.out(am5.ease.cubic)
                        });
                    }
                }
            });
            // sort axis items by index.
            // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }\r\n`);
        strArray.push(`\tvar year = endYear - count + 1;\r\n`);
        strArray.push(`\tvar interval = setInterval(function () {
            year++;

            if (year > endYear) {
                clearInterval(interval);
                clearInterval(sortInterval);
            }

            updateData();
        }, stepDuration);\r\n`);
        strArray.push(`\tvar sortInterval = setInterval(function () {
            sortCategoryAxis();
        }, 100);\r\n`);
        strArray.push(`\tfunction setInitialData() {
            var d = chartData[year];

            for (var n in d) {
                series.data.push({ network: n, value: d[n] });
                yAxis.data.push({ network: n });
            }
        }\r\n`);
        strArray.push(`\tfunction updateData() {
            var itemsWithNonZero = 0;

            if (chartData[year]) {
                label.set("text", year.toString());

                am5.array.each(series.dataItems, function (dataItem) {
                    var category = dataItem.get("categoryY");
                    var value = chartData[year][category];

                    if (value > 0) {
                        itemsWithNonZero++;
                    }

                    dataItem.animate({
                        key: "valueX",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                    dataItem.animate({
                        key: "valueXWorking",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear
                    });
                });

                yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
            }
        }\r\n`);
        strArray.push(`\tsetInitialData();\r\n`);
        strArray.push(`\tsetTimeout(function () {
            year++;
            updateData();
        }, 50);\r\n`);
        strArray.push(`\tseries.appear(1000);\r\n`);
        strArray.push(`\tchart.appear(1000, 100);\r\n`);

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [chartName, dataByYear, params, sliderParams, title]);

    return { htmlDiagram, createChart }
}