import { Grid } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useLayoutEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { getAnalyticsObjectsTree, getSDataChartTitle, getSDataTreemapYear, prepareSDataCategories, prepareSDataCategoriesV2, prepareSDataV2 } from "../sDataPage.utils";
import { AnalyticsObjectsSelector } from "../../../components/analyticsObjectsSelector/analyticsObjectsSelector";
import { useSDataParams } from "../sDataPage.hooks";
import { sDataBarDefaultParams, SDataBarParams } from "./sDataBarParams";
import { SDataChartType } from "../../../components/sDataChartType";
import { useSelector } from "react-redux";
import { SDataYearSelect } from "../../../components/sDataYearSelect";
import { isColor } from "../../../utils/chart.utils";
import { AnalyticsObjectsTreeSelector } from "../../../components/analyticsObjectsSelector";
import { SDataFormulaSelector } from "../../../components/sDataFormulaSelector/sDataFormulaSelector";

export const SDataBar = ({ data, compareSData, selectedAnalyticsObjects, formulaSData, indicator }) => {
    const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);
    const { params, actions } = useSDataParams(sDataBarDefaultParams);

    const selectedYear = useSelector((state) => state.sDataSlice.year);

    const sumObjectName = useMemo(() => {
        if (!data?.indicator?.elementsName || !data?.indicator?.sumValue) return undefined;
        return `Все ${data?.indicator?.elementsName}`;
    }, [data]);

    const isSumValue = useMemo(() => data?.indicator?.sumValue, [data]);
    const isSumValuesGraph = useMemo(() => data?.indicator?.isSumValuesGraph, [data]);

    const analyticsObjectsTree = useMemo(() => getAnalyticsObjectsTree(data?.analyticsObjects ?? []), [data]);
    const hasGroups = useMemo(() => {
        return analyticsObjectsTree?.some((item) => item.childrens.length > 0);
    }, [analyticsObjectsTree]);

    useLayoutEffect(() => {
        if (!data) return;
        const year = getSDataTreemapYear(data, selectedYear);
        // const chartDataArray = prepareSDataV2(data, selectedAnalyticsObjects, compareSData);
        // const chartDataArray = prepareSDataCategories(data, year, selectedAnalyticsObjects, compareSData);
        const chartDataArray = prepareSDataCategoriesV2(data, year, selectedAnalyticsObjects, compareSData, formulaSData, params);
        const chartTitle = getSDataChartTitle({ data, year, formulaSData });
        if (chartDataArray.length === 0) return;

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);

        root.setThemes([am5themes_Animated.new(root)]);

        am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: chartDataArray,
            filePrefix: "statbase_chart",
            pngOptions: {
                quality: 0.9,
            },
            jpgOptions: {
                quality: 0.9,
            },
        });

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ...(params.zoomEnable
                    ? {
                        wheelX: "panX",
                        wheelY: "zoomX",
                    }
                    : {}),
                pinchZoomX: true,
                layout: root.verticalLayout,
            })
        );

        if (params.showTitle) {
            chart.topAxesContainer.children.push(
                am5.Label.new(root, {
                    text: chartTitle,
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );
        }

        if (params.scrollbarEnable) {
            chart.set(
                "scrollbarY",
                am5.Scrollbar.new(root, { orientation: "vertical" })
            );
        }

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create Y-axis
        var yRenderer = am5xy.AxisRendererY.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true
        });

        yRenderer.grid.template.set("location", 1);

        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "columnName",
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        yAxis.data.setAll(chartDataArray[0].seriesData);

        var xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {
                    strokeOpacity: 0.1,
                    minGridDistance: 70
                })
            })
        );

        chartDataArray.forEach((chartData) => {
            const { seriesName, seriesData } = chartData;
            const series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: seriesName,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "value",
                    categoryYField: "columnName",
                    minBulletDistance: 50,
                    sequencedInterpolation: true,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: `{tooltipLabel}{categoryY}: {valueX} {unit}`,
                    }),
                })
            );
            series.data.setAll(seriesData);
            series.appear(500);

            series.columns.template.setAll({ cornerRadiusTR: 5, cornerRadiusBR: 5, strokeOpacity: 0 });
            series.columns.template.set("interactive", true);

            if (isColor(params.hoverColor)) {
                series.columns.template.states.create("hover", {
                    fill: am5.color(params.hoverColor),
                    stroke: am5.color(params.hoverColor),
                });
            }
        });


        // var yRenderer = yAxis.get("renderer");
        // yRenderer.labels.template.setAll({
        //     fontSize: "0.7em",
        //     rotation: -60,
        //     centerY: am5.p50,
        // });


        if (params.showLegend) {
            var legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                })
            );
            legend.data.setAll(chart.series.values);
        }

        return () => {
            root.dispose();
        };
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        compareSData,
        selectedYear,
        formulaSData
    ]);

    return (
        <>
            <Grid container flexDirection='row' gap='5px' height='450px' wrap="nowrap">
                {/* <AnalyticsObjectsSelector
                    analyticsObjects={data?.analyticsObjects}
                    label={data?.indicator?.elementsName}
                // maxObjects={10}
                // sumObjectName={sumObjectName}
                /> */}
                {hasGroups ?? isSumValuesGraph ?
                    <AnalyticsObjectsTreeSelector
                        analyticsObjects={analyticsObjectsTree}
                        label={data?.indicator?.elementsName}
                        analyticsObjectsElements={data?.analyticsObjects}
                        sumObjectName={sumObjectName}
                    /> :
                    <AnalyticsObjectsSelector
                        analyticsObjects={data?.analyticsObjects}
                        label={data?.indicator?.elementsName}
                        // maxObjects={10}
                        sumObjectName={sumObjectName}
                    />
                }
                <SDataBarParams params={params} actions={actions} isNotCompare={data?.indicator?.isNotCompare} />
            </Grid>
            <SDataYearSelect data={data} />
            <SDataChartType isSumValue={isSumValue} />
            <SDataFormulaSelector indicator={indicator} />
            {/* {!selectedAnalyticsObjects.length && <Grid sx={{ fontSize: '22px', fontWeight: 600 }}>Объекты аналитики не выбраны</Grid>} */}
            <Grid width={"100%"} container gap={"10px"}>
                <div
                    id={chartName}
                    style={{ width: params.width, height: params.height }}
                ></div>
                {/* <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} /> */}
            </Grid>
        </>
    );
};
