import { Chip, Grid } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAnalyticsObject, resetFormula, setChartType } from "../../store/reducer/sDataSlice";
import { checkResetChartTypes } from "./sDataChartType.utils";



export const SDataChartType = ({ isSumValue }) => {
    const dispatch = useDispatch();
    const chartType = useSelector((state) => state.sDataSlice.chartType);

    const onClick = useCallback((clickType) => {

        const preset1 = ['columns', 'line', 'categories', 'bar'];
        const preset2 = ['treemap', 'piechart', 'racechart'];
        // if (clickType === 'treemap' || chartType === 'treemap') {
        // dispatch(resetAnalyticsObject());
        // }
        // const isResetAnalyticsObject = (preset1.includes(clickType) && preset2.includes(chartType))
        //     || (preset2.includes(clickType) && preset1.includes(chartType))
        const isResetAnalyticsObject = checkResetChartTypes(clickType, chartType);
        if (isResetAnalyticsObject) {
            dispatch(resetAnalyticsObject());
        }
        dispatch(resetFormula());
        dispatch(setChartType(clickType));
    }, [chartType, dispatch]);


    return (
        <Grid container flexDirection='row' alignItems='flex-start' gap='15px'>
            <Grid container gap='5px'>
                <Chip
                    key={`columns`}
                    color={(chartType === 'columns') ? 'primary' : 'default'}
                    label='Columns'
                    onClick={() => onClick('columns')}
                />
                <Chip
                    key={`line`}
                    color={(chartType === 'line') ? 'primary' : 'default'}
                    label='Lines'
                    onClick={() => onClick('line')}
                />
                <Chip
                    key={`categories`}
                    color={(chartType === 'categories') ? 'primary' : 'default'}
                    label='Categories'
                    onClick={() => onClick('categories')}
                />
                <Chip
                    key={`bar`}
                    color={(chartType === 'bar') ? 'primary' : 'default'}
                    label='Bar'
                    onClick={() => onClick('bar')}
                />
                {isSumValue && <Chip
                    key={`treemap`}
                    color={(chartType === 'treemap') ? 'primary' : 'default'}
                    label='Treemap'
                    onClick={() => onClick('treemap')}
                />}
                {isSumValue && <Chip
                    key={`piechart`}
                    color={(chartType === 'piechart') ? 'primary' : 'default'}
                    label='PieChart'
                    onClick={() => onClick('piechart')}
                />}
                <Chip
                    key={`racechart`}
                    color={(chartType === 'racechart') ? 'primary' : 'default'}
                    label='RaceChart'
                    onClick={() => onClick('racechart')}
                />
            </Grid>
        </Grid >
    );
}
