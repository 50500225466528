import { useCallback, useEffect, useMemo } from "react";
import { Autocomplete, Chip, CircularProgress, Grid, IconButton, LinearProgress, TextField, Tooltip, tooltipClasses } from "@mui/material";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { styled } from '@mui/material/styles';
import { useGetIndicatorFormulasQuery } from "../../store/api/info.api";
import { useDispatch, useSelector } from "react-redux";
import { setCompareCountry, setCompareIndicator, setFormula } from "../../store/reducer/sDataSlice";
import { SDataFormulaRegionSelector } from "./sDataFormulaRegionSelector/sDataFormulaRegionSelector";
import { useParams } from "react-router-dom";

const textGridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
}

export const SDataFormulaSelector = ({ indicator }) => {
    const dispatch = useDispatch();
    const { sDataIndicatorId: indicatorId, countryId } = useParams();

    const { data: formulas, isLoading: formulasIsLoading } = useGetIndicatorFormulasQuery(indicatorId);

    const currentFormula = useSelector((state) => state.sDataSlice.formula);
    const formulaId = useMemo(() => currentFormula?.bitrixID ?? null, [currentFormula]);

    const onChangeHandler = useCallback((formula) => {
        dispatch(setCompareIndicator(null));
        dispatch(setCompareCountry(null));
        dispatch(setFormula(formula));
    }, [dispatch]);

    const resetFormulaHandler = useCallback(() => {
        dispatch(setFormula(null));
    }, [dispatch]);

    const indicatorType = useMemo(() => {
        if (indicator?.percentageValue?.value) {
            return "percentage";
        }
        if (indicator?.sumValue?.value) {
            return "sum";
        }
        if (indicator?.valuePerUnit?.value) {
            return "perUnit";
        }
    }, [indicator]);

    const defaultButtons = useMemo(() => {
        let baseData = [];
        if (indicatorType === 'sum' && indicator?.isFullDataset) {
            baseData.push({ bitrixID: 'proc', name: 'Доля', unit: '%' });
        }
        if (['sum', 'perUnit'].includes(indicatorType)) {
            baseData.push({ bitrixID: 'sum', name: 'Изменение (сумма)' });
            baseData.push({ bitrixID: 'izmproc', name: 'Изменение (проценты)', unit: '%' });
        }
        return baseData;
    }, [indicator, indicatorType])


    if (!formulas || formulas?.length === 0) return null;

    return (
        <Grid container flexDirection='column' alignItems='flex-start' gap='5px'>
            {formulasIsLoading && <Grid width='100%'><LinearProgress /></Grid>}
            {(!formulasIsLoading) &&
                <Grid container gap='5px'>
                    <Chip
                        key={`base_data_0`}
                        color={(!formulaId || formulaId == 0) ? 'primary' : 'default'}
                        label='Базовые данные'
                        onClick={resetFormulaHandler}
                    />
                    {defaultButtons.map((item, index) =>
                        <Chip
                            key={`base_${index}`}
                            color={item.bitrixID === formulaId ? 'primary' : 'default'}
                            label={item.name}
                            // disabled={disabled}
                            onClick={() => onChangeHandler(item)}
                        />
                    )}
                    {[...formulas]?.sort((a, b) => a?.sort - b?.sort)?.map((item, index) =>
                        <Chip
                            key={`special_${item.bitrixID}_${index}`}
                            color={item.bitrixID === formulaId ? 'primary' : 'default'}
                            label={item.name}
                            // disabled={disabled}
                            onClick={() => onChangeHandler(item)}
                        />
                    )}
                </Grid>
            }
            <SDataFormulaRegionSelector countryId={countryId} />
            {currentFormula?.text && <Grid sx={textGridSx}>{currentFormula?.text}</Grid>}
        </Grid>
    );
}
