import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './api.const';

export const dictionariesApi = createApi({
    reducerPath: 'dictionariesApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
    endpoints: (builder) => ({
        getIndicators: builder.query({
            query: () => 'get_indicators.php',
        }),
        getOrganizations: builder.query({
            query: () => 'get_organizations.php',
        }),
        getCountries: builder.query({
            query: () => 'get_countries.php',
        }),
        getIndicatorsByCountry: builder.query({
            query: (countryId) => `get_indicators_by_country.php?country=${countryId}`,
        }),
        getSDataIndicators: builder.query({
            query: () => 'get_sdata_indicators.php',
        }),
        getSDataIndicatorsByCountry: builder.query({
            query: (countryId) => `get_indicators_by_country.php?country=${countryId}&sDataOnly=1`,
        }),
        getCountriesByIndicator: builder.query({
            query: (indicatorId) => `get_countries_by_indicator.php?indicator=${indicatorId}`,
        }),
        getIndicatorsForCompare: builder.query({
            query: (indicatorId) => `get_compare_indicators.php?indicator=${indicatorId}`,
        }),
        getCountryOrganizations: builder.query({
            query: (countryId) => `get_country_organizations.php?country=${countryId}`,
        }),
    }),
})

export const {
    useGetIndicatorsQuery,
    useGetOrganizationsQuery,
    useGetCountriesQuery,
    useGetIndicatorsByCountryQuery,
    useGetSDataIndicatorsQuery,
    useGetSDataIndicatorsByCountryQuery,
    useGetCountriesByIndicatorQuery,
    useGetIndicatorsForCompareQuery,
    useGetCountryOrganizationsQuery,
} = dictionariesApi;