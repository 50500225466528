export const sDataBarDefaultParams = {
    width: "100%",
    height: "600px",
    showLegend: true,
    zoomEnable: true,
    scrollbarEnable: true,
    showTitle: true,
    startXAxisFromZero: false,
    hoverColor: '#297373',
    hideXAxis: false,
    sortByValue: false,
    sortDesc: false,
};
