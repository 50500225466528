import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { headerDescriptionTextSx, headerTextSx } from "./indicatorHeaderView.styles";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
    },
});

export const IndicatorHeaderView = ({ indicator, titlePostfix }) => {

    const selectedYear = useSelector((state) => state.sDataSlice.year);
    const chartType = useSelector((state) => state.sDataSlice.chartType);

    const title = useMemo(() => {
        if (['treemap', 'categories', 'piechart'].includes(chartType)) {
            const yearStr = selectedYear ? `| ${selectedYear}` : '';
            return `${indicator?.name} ${yearStr}`;
        }
        return indicator?.name;
    }, [indicator, chartType]);

    return (
        <>
            {!indicator ? (
                <LinearProgress />
            ) : (
                <>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        mb="10px"
                    >
                        <Grid container sx={headerTextSx}>
                            {title}
                            {indicator?.description && (
                                <NoMaxWidthTooltip
                                    title={
                                        <Grid sx={{ fontSize: "14px" }}>
                                            {indicator?.description}
                                        </Grid>
                                    }
                                >
                                    <IconButton>
                                        <InfoRoundedIcon color="info" />
                                    </IconButton>
                                </NoMaxWidthTooltip>
                            )}
                        </Grid>
                        <Grid width="auto" container gap="5px">
                            {indicator?.sources?.map((item) => (
                                <Chip label={item?.name} />
                            ))}
                        </Grid>
                    </Grid>
                    {indicator?.text && (
                        <Grid container sx={headerDescriptionTextSx}>
                            {indicator?.text}
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};
