import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { BaseTestChart } from './pages/baseTestChart';
import { Default } from './pages/default';
import { TreemapGraph } from './pages/treemapGraph';
import { PieGraph } from './pages/pieGraph/pieGraph';
import { RaceGraph } from './pages/raceGraph/raceGraph';
import { ColumnsCountriesChart } from './pages/columnsCountriesChart';
import { MapGraph } from './pages/mapGraph';
import { DemoAccess } from './pages/demoAccess';
import { LinesChart } from './pages/linesChart/linesChart';
import { SDataPage } from './pages/sDataPage';



const router = createBrowserRouter([
  {
    path: "/indicator/:indicatorId/country/:countryId",
    element: <BaseTestChart />,
  },
  {
    path: "/indicator/:indicatorId/treemap",
    element: <TreemapGraph />
  },
  {
    path: "/indicator/:indicatorId/piechart",
    element: <PieGraph />
  },
  {
    path: "/indicator/:indicatorId/columncountries",
    element: <ColumnsCountriesChart />
  },
  {
    path: "/indicator/:indicatorId/race",
    element: <RaceGraph />
  },
  {
    path: "/indicator/:indicatorId/map",
    element: <MapGraph />
  },
  {
    path: "/indicator/:indicatorId/line",
    element: <LinesChart />
  },
  {
    path: "/demo-access",
    element: <DemoAccess />
  },
  {
    path: "/indicator/:sDataIndicatorId/country/:countryId/sdata",
    element: <SDataPage />
  },
  {
    path: "*",
    element: <Default />,
  },
]);

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      {/* <BaseTestChart /> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
