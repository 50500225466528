import { useCallback, useMemo } from "react";
import { Autocomplete, CircularProgress, Grid, IconButton, TextField, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useGetCountryOrganizationsQuery } from "../../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setFormulaRegion } from "../../../store/reducer/sDataSlice";
import { useGetOrganizationCountriesQuery } from "../../../store/api/info.api";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

const textGridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    // gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const organizationSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    gap: '10px',
    alignItems: 'center',
}

const autocompleteSx = {
    width: '30%',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export const SDataFormulaRegionSelector = ({ countryId }) => {
    const dispatch = useDispatch();

    const currentFormula = useSelector((state) => state.sDataSlice.formula);
    const currentFormulaRegion = useSelector((state) => state.sDataSlice.formulaRegion);

    const { data: organizations } = useGetCountryOrganizationsQuery(countryId);
    const {
        data: organizationCountries,
        isFetching: isLoadingOrganizationCountries
    } = useGetOrganizationCountriesQuery(currentFormulaRegion?.id, { skip: !currentFormulaRegion?.id });


    const isProcType = useMemo(() => currentFormula?.bitrixID === 'proc', [currentFormula]);

    const organizationCountriesText = useMemo(() => {
        const names = organizationCountries?.map((item) => item?.name);
        return `Страны: ${names?.join(', ')}`;
    }, [organizationCountries]);

    const defaultRegion = useMemo(() => ({ id: 'all', label: 'Весь мир' }), []);

    const organizationDictionary = useMemo(() => {
        let dictionary = [defaultRegion];
        if (!organizations) return dictionary;

        const sortedOrganization = [...organizations].sort((a, b) => Number(a?.sort) - Number(b?.sort));
        sortedOrganization.forEach((item) => {
            if (item?.agrType !== null) {
                dictionary.push({ id: item.bitrixID, label: item.name })
            }
        });
        return dictionary;
    }, [organizations]);

    const onChangeHandler = useCallback((_, formulaRegion) => {
        dispatch(setFormulaRegion(formulaRegion));
    }, [dispatch]);

    if (!isProcType) return null;

    return (
        <Grid container flexDirection='column' alignItems='flex-start' gap='5px'>
            {/* {formulasIsLoading && <Grid width='100%'><LinearProgress /></Grid>} */}
            <Grid container sx={organizationSx}>
                <Autocomplete
                    size="small"
                    sx={autocompleteSx}
                    onChange={onChangeHandler}
                    options={organizationDictionary}
                    value={currentFormulaRegion ?? defaultRegion}
                    defaultValue={defaultRegion}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Организация" />}
                />
                {Boolean(organizationCountries) && currentFormulaRegion &&
                    <>
                        {isLoadingOrganizationCountries ? <CircularProgress size='26px' /> :
                            <NoMaxWidthTooltip
                                title={
                                    <Grid sx={{ fontSize: '14px' }}>
                                        {organizationCountriesText}
                                    </Grid>
                                }
                            >
                                <IconButton>
                                    <InfoRoundedIcon color="info" />
                                </IconButton>
                            </NoMaxWidthTooltip>
                        }
                    </>
                }
            </Grid>
        </Grid>
    );
}
