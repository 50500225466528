export const mainGridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    flexDirection: 'row',
    gap: '10px',
    '@media (max-width: 600px)': {
        flexDirection: 'column',
    }
}

export const paramsSx = {
    // border: '1px solid #e0e0e0',
    // borderRadius: '10px',
    // padding: '10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    // width: 'calc(70% - 20px)',
    '@media (max-width: 600px)': {
        width: '100%',
        alignItems: 'center',
    }
}

export const paramsTitleSx = {
    fontSize: '32px',
    fontWeight: '600',
    mb: '5px',
    width: 'auto',
    '@media (max-width: 600px)': {
        fontSize: '24px',
        justifyContent: 'center',
    }
}

export const paramsTextField = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}
