import { Grid } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useLayoutEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { getAnalyticsObjectsTree, getSDataChartTitle, getSDataTreemapYear, prepareSData, prepareSDataPie, prepareSDataTreemap, prepareSDataTreemapV2 } from "../sDataPage.utils";
import { AnalyticsObjectsSelector, AnalyticsObjectsTreeSelector } from "../../../components/analyticsObjectsSelector";
import { useSDataParams } from "../sDataPage.hooks";
import { SDataPieParams, sDataPieDefaultParams } from "./sDataPieParams";
import { SDataChartType } from "../../../components/sDataChartType";
import { SDataYearSelect } from "../../../components/sDataYearSelect";
import { useSelector } from "react-redux";
import { useSDataPie } from "./sDataPie.hooks";
import { CopyHtmlGrid } from "../../../components/copyHtmlGrid";

export const SDataPie = ({ data, selectedAnalyticsObjects }) => {
    const chartName = useMemo(() => `statbase_${nanoid(10)}`, []);
    const { params, actions } = useSDataParams(sDataPieDefaultParams);
    const selectedYear = useSelector((state) => state.sDataSlice.year);

    const analyticsObjectsTree = useMemo(() => getAnalyticsObjectsTree(data?.analyticsObjects ?? []), [data]);

    const hasGroups = useMemo(() => {
        return analyticsObjectsTree?.some((item) => item.childrens.length > 0);
    }, [analyticsObjectsTree]);

    const { htmlDiagram, createChart } = useSDataPie(
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    );

    useLayoutEffect(() => {
        if (!data) return;
        const year = getSDataTreemapYear(data, selectedYear);
        // const chartDataArray = prepareSDataTreemap(data, year, params, selectedAnalyticsObjects);
        const chartData = prepareSDataPie(data, year, params, selectedAnalyticsObjects);
        const chartTitle = getSDataChartTitle({ data, year });
        // if (chartDataArray.length === 0) return;

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);
        root.setThemes([am5themes_Animated.new(root)]);

        am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            dataSource: chartData,
            filePrefix: "statbase_chart",
            pngOptions: {
                quality: 0.9,
            },
            jpgOptions: {
                quality: 0.9,
            },
        });

        var container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );

        if (params.showTitle) {
            container.children.push(
                am5.Label.new(root, {
                    text: chartTitle,
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );
        }

        var chart = container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
            })
        );

        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                name: chartName,
                categoryField: "columnName",
                valueField: "value",
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{tooltipLabel}: {value} {unit}",
                }),
            })
        );

        series.data.setAll(chartData);
        if (params.showLegend) {
            var legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                    paddingLeft: 100,
                    paddingRight: 100,
                    // layout: root.horizontalLayout
                })
            );
            legend.data.setAll(series.dataItems);
        }

        return () => {
            root.dispose();
        };
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    ]);

    return (
        <>
            <Grid container flexDirection='row' gap='5px' height='310px' wrap="nowrap">
                {/* <AnalyticsObjectsSelector
                    analyticsObjects={data?.analyticsObjects}
                    label={data?.indicator?.elementsName}
                /> */}
                {hasGroups && <AnalyticsObjectsTreeSelector
                    analyticsObjects={analyticsObjectsTree}
                    label={data?.indicator?.elementsName}
                    hideElements={true}
                />}
                <SDataPieParams params={params} actions={actions} />
            </Grid>
            <SDataYearSelect data={data} />
            <SDataChartType isSumValue={data?.indicator?.sumValue} />
            <Grid width={"100%"} container gap={"10px"}>
                <div
                    id={chartName}
                    style={{ width: params.width, height: params.height }}
                ></div>
                <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
            </Grid>
        </>
    );
};
