import { useCallback } from "react";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


export const CopyHtmlGrid = ({ htmlDiagram, createChart }) => {

    const copyText = useCallback(() => {
        if (!htmlDiagram) return;
        navigator.clipboard.writeText(htmlDiagram)
    }, [htmlDiagram]);

    return (
        <Grid container>
            <Grid container>
                <Grid><Button variant="outlined" onClick={createChart}>Показать код</Button></Grid>
                <Grid>
                    <IconButton color="info" disabled={!htmlDiagram} onClick={copyText}>
                        <ContentCopyRoundedIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {htmlDiagram && <TextField fullWidth multiline maxRows={10} value={htmlDiagram} />}
        </Grid>
    );
}
