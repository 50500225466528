import { Autocomplete, Button, Divider, Grid, IconButton, LinearProgress, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetIndicatorsQuery, useGetSDataIndicatorsQuery } from "../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import { addAnalyticsObject, resetAnalyticsObject, setAnalyticsObjects } from "../../store/reducer/sDataSlice";
import { AnalyticsObjectsTreeGrid } from "./analyticsObjectsTreeGrid";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

const gridSx = {
    display: 'flex',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px 0px 10px 5px',
    // gap: '10px',
    width: '30%',
    height: '100%',
    flexDirection: 'column'
}

const listSx = {
    display: 'flex',
    // padding: '10px',
    flexDirection: 'column',
    overflowY: 'scroll'
}

const itemSx = {
    justifyContent: 'flex-start',
    textTransform: 'unset',
    textAlign: 'left',
    // whiteSpace: 'nowrap',

}


export const AnalyticsObjectsTreeSelector = ({ analyticsObjects, analyticsObjectsElements, label, maxObjects, hideLabel, sumObjectName, hideElements = false }) => {
    const dispatch = useDispatch();
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);

    const selectIsDisabled = useMemo(() => {
        if (!maxObjects) return false;
        return selectedAnalyticsObjects.length >= maxObjects
    }, [selectedAnalyticsObjects, maxObjects]);

    const onClick = useCallback((analyticsObject) => {
        const { childrens, ...other } = analyticsObject;
        dispatch(addAnalyticsObject(other));
    }, [dispatch]);

    const sortedAnalyticsObjects = useMemo(() => {
        return [...analyticsObjects]?.sort((a, b) => Number(a.sort) - Number(b.sort));
    }, [analyticsObjects]);

    const title = useMemo(() => {
        if (!label) return 'Объекты аналитики';
        return `Объекты аналитики | ${label}`;
    }, [label]);

    const setAllHandler = useCallback(() => {
        if (analyticsObjectsElements?.length > 0) {
            const sortedElements = [...analyticsObjectsElements]?.sort((a, b) => Number(a.sort) - Number(b.sort));
            dispatch(setAnalyticsObjects(sortedElements));
        }
    }, [analyticsObjectsElements]);

    const resetHandler = useCallback(() => {
        dispatch(resetAnalyticsObject());
    }, [dispatch]);

    const sumObjectAlredySelected = useMemo(() =>
        selectedAnalyticsObjects?.find((selectedItem) => selectedItem.bitrixID === 0)
        , [selectedAnalyticsObjects]);


    return (
        <Grid sx={gridSx}>
            <Grid container justifyContent='center' alignItems='center'>
                {!hideElements && <>
                    <Tooltip title="Добавить все">
                        <IconButton size="small" onClick={setAllHandler}>
                            <AddCircleOutlineRoundedIcon color="success" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить все">
                        <IconButton size="small" onClick={resetHandler}>
                            <HighlightOffRoundedIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </>}
                {/* <Grid fontSize='20px' fontWeight={600}>{title}</Grid> */}
                {!hideLabel && <Grid fontSize='20px' fontWeight={600}>{title}</Grid>}
            </Grid>
            <Grid sx={listSx}>
                {sumObjectName &&
                    <>
                        <Button
                            variant="text"
                            fullWidth
                            sx={itemSx}
                            color="inherit"
                            onClick={() => onClick({ bitrixID: 0, name: sumObjectName })}
                            disabled={selectIsDisabled || sumObjectAlredySelected}
                        >
                            {sumObjectName}
                        </Button>
                        <Divider />
                    </>
                }
                {sortedAnalyticsObjects?.map((item, index) => {
                    const analyticsObjectKeys = selectedAnalyticsObjects.map((analyticsObject) => analyticsObject.bitrixID);
                    const isSelect = analyticsObjectKeys.includes(item.bitrixID);
                    if (item?.childrens?.length > 0) {
                        return <>
                            <Button
                                key={`analyticsObjectsTreeSelector_${index}_button`}
                                variant="text"
                                fullWidth
                                sx={itemSx}
                                color="inherit"
                                onClick={() => onClick(item)}
                                disabled={selectIsDisabled || isSelect}
                            >
                                {item?.name}
                            </Button>
                            <Divider />
                            <AnalyticsObjectsTreeGrid
                                key={`analyticsObjectsTreeSelector_${index}_grid`}
                                subIndex={index}
                                analyticsObjects={item?.childrens}
                                groupDisabled={isSelect}
                                hideElements={hideElements}
                            />
                        </>

                    }
                    if (hideElements) return null;
                    return <>
                        <Button
                            key={`analyticsObjectsTreeSelector_${index}_button`}
                            variant="text"
                            fullWidth
                            sx={itemSx}
                            color="inherit"
                            onClick={() => onClick(item)}
                            disabled={selectIsDisabled || isSelect}
                        >
                            {item?.name}
                        </Button>
                        <Divider />
                    </>
                })}
            </Grid>
        </Grid>
    );
}
