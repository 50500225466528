import { Grid, LinearProgress, Tooltip } from "@mui/material";
import { Header } from "../../components/header";
import { useParams } from "react-router-dom";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { headerGridSx } from "./sDataPage.styles";
import { useCheckAccess } from "../../hooks/app.hooks";
import { ChartTypeLinks } from "../../components/chartTypeLinks";
import { ChooseSDataIndicator } from "../../components/chooseSDataIndicator/chooseSDataIndicator";
import { useGetSDataByFormulaQuery, useGetSDataByIndicatorQuery } from "../../store/api/vizualization.api";
import { SDataColumns } from "./sDataColumns";
import { AnalyticsObjectsView } from "../../components/analyticsObjectsView/analyticsObjectsView";
import { useSelector } from "react-redux";
import {
    useGetOneCountryQuery,
    useGetOneIndicatorQuery,
} from "../../store/api/info.api";
import { SDataLine } from "./sDataLine";
import { SDataTreemap } from "./sDataTreemap";
import { SDataCategories } from "./sDataCategories";
import { SDataPie } from "./sDataPie";
import { SDataRace } from "./sDataRace";
import { IndicatorHeaderView } from "../../components/indicatorHeaderView";
import { useSDataByFormula } from "./sDataPage.hooks";
import { SDataBar } from "./sDataBar/sDataBar";

const vizualizationType = "sdata";
export const SDataPage = () => {
    useCheckAccess();
    const { sDataIndicatorId, countryId } = useParams();
    const { data: country } = useGetOneCountryQuery(countryId, { skip: !countryId });
    const { data: indicator } = useGetOneIndicatorQuery(sDataIndicatorId, { skip: !sDataIndicatorId });

    const chartType = useSelector((state) => state.sDataSlice.chartType);
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);
    const compareCountry = useSelector((state) => state.sDataSlice.compareCountry);
    const compareIndicator = useSelector((state) => state.sDataSlice.compareIndicator);
    const selectedFormula = useSelector((state) => state.sDataSlice.formula);

    const { data: sData, isFetching: sDataIsLoading } = useGetSDataByIndicatorQuery({ indicatorId: sDataIndicatorId, countryId: countryId })

    const { data: compareSDataResponse, isFetching: compareSDataIsLoading } =
        useGetSDataByIndicatorQuery({
            indicatorId: compareIndicator?.id ?? sDataIndicatorId,
            countryId: compareCountry?.id ?? countryId
        }, { skip: !compareCountry && !compareIndicator })

    const { data: formulaSDataResponse, isLoading: formulaSDataIsLoading } = useSDataByFormula(sDataIndicatorId, countryId);
    // const { data: formulaSDataResponse, isFetching: formulaSDataIsLoading } =
    //     useGetSDataByFormulaQuery({
    //         indicatorId: sDataIndicatorId,
    //         countryId: countryId,
    //         formulaId: selectedFormula?.bitrixID ?? null,
    //     }, { skip: !selectedFormula?.bitrixID })

    const hasData = useMemo(() => !!sData && sData?.data, [sData])

    const compareSData = useMemo(() => {
        if ((!compareCountry && !compareIndicator) || compareSDataIsLoading) return undefined;
        return compareSDataResponse;
    }, [compareSDataResponse, compareCountry, compareIndicator, compareSDataIsLoading])

    const formulaSData = useMemo(() => {
        if (!selectedFormula || formulaSDataIsLoading || !selectedAnalyticsObjects?.length) return undefined;
        return formulaSDataResponse?.map((item) => ({
            formula: selectedFormula,
            ...item
        }))
    }, [formulaSDataResponse, selectedFormula, formulaSDataIsLoading, selectedAnalyticsObjects])

    const pageIsLoading = sDataIsLoading || compareSDataIsLoading || formulaSDataIsLoading;

    return (
        <>
            <Header />
            <Grid container sx={headerGridSx}>
                <ChartTypeLinks type={vizualizationType} />
                {sDataIsLoading && (
                    <Grid width="100%">
                        <LinearProgress />
                    </Grid>
                )}
                <ChooseSDataIndicator indicator={indicator} country={country} />
                <IndicatorHeaderView indicator={indicator} />
                <AnalyticsObjectsView isExcluded={false} />
                {!hasData && <Grid sx={{ mt: '30px', fontSize: '28px', fontWeight: 600 }}>Нет данных</Grid>}
                {hasData && chartType === 'columns' &&
                    <SDataColumns
                        data={sData}
                        compareSData={compareSData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                        formulaSData={formulaSData}
                        indicator={indicator}
                        isLoading={pageIsLoading}
                    />
                }
                {hasData && chartType === 'line' &&
                    <SDataLine
                        data={sData}
                        compareSData={compareSData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                        formulaSData={formulaSData}
                        indicator={indicator}
                        isLoading={pageIsLoading}
                    />
                }
                {hasData && chartType === 'treemap' &&
                    <SDataTreemap
                        data={sData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                    />
                }
                {hasData && chartType === 'categories' &&
                    <SDataCategories
                        data={sData}
                        compareSData={compareSData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                        formulaSData={formulaSData}
                        indicator={indicator}
                        isLoading={pageIsLoading}
                    />
                }
                {hasData && chartType === 'bar' &&
                    <SDataBar
                        data={sData}
                        compareSData={compareSData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                        formulaSData={formulaSData}
                        indicator={indicator}
                        isLoading={pageIsLoading}
                    />
                }
                {hasData && chartType === 'piechart' &&
                    <SDataPie
                        data={sData}
                        compareSData={compareSData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                    />
                }
                {hasData && chartType === 'racechart' &&
                    <SDataRace
                        data={sData}
                        selectedAnalyticsObjects={selectedAnalyticsObjects}
                    />
                }
            </Grid>
        </>
    );
};
