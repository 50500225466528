import {
    Checkbox,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from "@mui/material";

import {
    mainGridSx,
    paramsSx,
    paramsTextField,
    paramsTitleSx,
} from "./sDataTreemapParams.styles";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { SDataCompare } from "../../../../components/sDataCompare/sDataCompare";


export const SDataTreemapParams = ({
    params,
    actions
}) => {
    const { changeTextField, changeBoolField } = actions;

    return (
        <Grid display='flex' sx={mainGridSx}>
            <Grid container sx={paramsSx} width="100%">
                <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid container sx={paramsTitleSx}>
                        Настройки графика
                    </Grid>
                    <Grid>
                        <Tooltip title="Сбросить">
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Ширина"
                        size="small"
                        value={params.width}
                        onChange={(e) => changeTextField("width", e)}
                    />
                    <TextField
                        sx={paramsTextField}
                        label="Высота"
                        size="small"
                        value={params.height}
                        onChange={(e) => changeTextField("height", e)}
                    />
                </Grid>
                <Grid container gap='10px' alignItems='baseline'>
                    <TextField type="number" sx={paramsTextField} label='Количество элементов' size="small" value={params.itemsCount} onChange={(e) => changeTextField('itemsCount', e)} />
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.sortDesc} onClick={() => changeBoolField('sortDesc')} />
                    Сортировать в обратном порядке
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.sumOther} onClick={() => changeBoolField('sumOther')} />
                    Объединить исключенные элементы
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showTitle} onClick={() => changeBoolField('showTitle')} />
                    Отображать заголовок
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.groupsDisabled} onClick={() => changeBoolField('groupsDisabled')} />
                    Отключить группировку
                </Grid>
            </Grid>
            {/* <Grid>
                <Divider orientation="vertical" />
            </Grid>
            <SDataCompare /> */}
        </Grid>
    );
};
