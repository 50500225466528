
export const headerGridSx = {
    paddingTop: '15px',
    paddingLeft: '60px',
    paddingRight: '60px',
    flexDirection: 'column',
    gap: '10px',
    mb: '30px',
    '@media (max-width: 600px)': {
        paddingLeft: '10px',
        paddingRight: '10px',
    }
}

export const headerTextSx = {
    fontSize: '36px',
    fontWeight: '700',
    alignItems: 'baseline',
    width: 'auto',
    gap: '5px',
    '@media (max-width: 600px)': {
        width: '100%',
        mb: '10px',
        fontSize: '24px',
        alignItems: 'center',
    }
}

export const headerDescriptionTextSx = {
    textAlign: 'left',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: '10px',
    maxWidth: '70%',
    '@media (max-width: 600px)': {
        maxWidth: 'unset',
        width: '100%',
    }
}