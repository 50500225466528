import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    year: null,
    analyticsObjects: [],
    compareCountry: null,
    compareIndicator: null,
    chartType: 'columns',
    formula: null,
    formulaRegion: null,
};

export const sDataSlice = createSlice({
    name: "sData",
    reducerPath: "sDataSlice",
    initialState,
    reducers: {
        setYear: (state, action) => {
            state.year = action.payload;
        },
        addAnalyticsObject: (state, action) => {
            const analyticObject = action.payload;
            const alreadyExists = state.analyticsObjects?.find((item) => item.bitrixID === analyticObject.bitrixID);
            if (!alreadyExists) {
                state.analyticsObjects = [...state.analyticsObjects, analyticObject];
            }
        },
        deleteAnalyticsObject: (state, action) => {
            const analyticObjectID = action.payload;
            state.analyticsObjects = state.analyticsObjects?.filter((item) => item.bitrixID !== analyticObjectID);
        },
        setAnalyticsObjects: (state, action) => {
            state.analyticsObjects = action.payload;
        },
        resetAnalyticsObject: (state, _) => {
            state.analyticsObjects = [];
        },
        setCompareCountry: (state, action) => {
            state.compareCountry = action.payload;
        },
        setCompareIndicator: (state, action) => {
            state.compareIndicator = action.payload;
        },
        setChartType: (state, action) => {
            state.chartType = action.payload;
        },
        setFormula: (state, action) => {
            state.formula = action.payload;
        },
        setFormulaRegion: (state, action) => {
            state.formulaRegion = action.payload;
        },
        resetFormula: (state, _) => {
            state.formula = null;
            state.formulaRegion = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setYear,
    addAnalyticsObject,
    deleteAnalyticsObject,
    setAnalyticsObjects,
    resetAnalyticsObject,
    setCompareCountry,
    setChartType,
    setCompareIndicator,
    setFormula,
    setFormulaRegion,
    resetFormula
} = sDataSlice.actions;
