import { useCallback, useEffect, useMemo, useState } from "react";

const defaultParams = {
    width: "100%",
    height: "600px",
    itemsCount: 10,
    yearCount: 10,
    valuesInsideGraph: false,
    showTitle: true,
    unit: '',
    stepDuration: 2,
};

export const useRaceGraphParams = ({ indicator }) => {

    const [params, setParams] = useState(defaultParams);

    useEffect(() => {
        const unit = indicator?.unit ?? '';
        indicator && setParams((prev) => ({
            ...prev,
            unit: unit,
        }))
    }, [indicator])

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeNumberField = (name, event) => {
        const value = Number(event.target.value);
        // if (value <= maxItemsCount)
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }))
    }

    const resetParams = useCallback(() => {
        setParams((prev) => ({ ...defaultParams, unit: prev.unit }));
    }, [])

    const actions = {
        changeTextField,
        changeBoolField,
        changeNumberField,
        resetParams,
    }

    return { params, actions }
}

export const useRaceGraphSliderParams = (data) => {

    const [yearRange, setYearRange] = useState([0, 0]);

    const { min, max, marks, showSlider } = useMemo(() => {
        const showSlider = Boolean(data?.yearFrom) && Boolean(data?.yearTo);
        const min = data?.yearFrom ?? null;
        const max = data?.yearTo ?? null;
        const marks = [{ value: min, label: min }, { value: max, label: max }];
        return { min, max, marks, showSlider }
    }, [data]);

    useEffect(() => {
        if (!min || !max) return;
        setYearRange([min, max]);
    }, [min, max]);

    const changeSlider = (event) => {
        const value = event.target.value;
        setYearRange(value);
    }

    return { showSlider, min, max, marks, yearRange, changeSlider }
    // return { showSlider: true, min: 0, max: 100, marks: undefined, yearRange, changeSlider }
}