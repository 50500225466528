import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetDemoAccessQuery } from "../store/api/general.api";

export const useCheckAccess = () => {
  const navigate = useNavigate();
  const [checkAccess] = useLazyGetDemoAccessQuery();

  useEffect(() => {
    const accessCode = localStorage.getItem("accessCode");
    const activationTime = localStorage.getItem("activationTime");

    if (!accessCode || !activationTime) {
      navigate("/demo-access");
    }

    void checkAccess(accessCode)
      .unwrap()
      .then((res) => {
        const accessIsAllowed = res?.accessIsAllowed;
        if (!accessIsAllowed) {
          navigate("/demo-access");
        }
      });
  }, []);
};
