import {
    Checkbox,
    Divider,
    Grid,
    IconButton,
    Slider,
    TextField,
    Tooltip,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import {
    mainGridSx,
    paramsSx,
    paramsTextField,
    paramsTitleSx,
} from "./sDataColumnsParams.styles";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { SDataCompare } from "../../../../components/sDataCompare/sDataCompare";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
    },
});

export const SDataColumnsParams = ({
    params,
    actions,
    isNotCompare,
    sliderParams
}) => {
    const { changeTextField, changeBoolField } = actions;
    const { showSlider, min, max, marks, yearRange, changeSlider } = sliderParams;

    return (
        <Grid display='flex' sx={mainGridSx}>
            <Grid container sx={paramsSx} >
                <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid container sx={paramsTitleSx}>
                        Настройки графика
                    </Grid>
                    <Grid>
                        <Tooltip title="Сбросить">
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Ширина"
                        size="small"
                        value={params.width}
                        onChange={(e) => changeTextField("width", e)}
                    />
                    <TextField
                        sx={paramsTextField}
                        label="Высота"
                        size="small"
                        value={params.height}
                        onChange={(e) => changeTextField("height", e)}
                    />
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Цвет при наведении"
                        size="small"
                        value={params.hoverColor}
                        onChange={(e) => changeTextField("hoverColor", e)}
                    />
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.zoomEnable}
                        onClick={() => changeBoolField("zoomEnable")}
                    />
                    Включить масштабирование по оси X
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.scrollbarEnable}
                        onClick={() => changeBoolField("scrollbarEnable")}
                    />
                    Отображать полосу прокрутки
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.showLegend}
                        onClick={() => changeBoolField("showLegend")}
                    />
                    Отображать легенду
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.showTitle}
                        onClick={() => changeBoolField("showTitle")}
                    />
                    Отображать заголовок
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.startYAxisFromZero}
                        onClick={() => changeBoolField("startYAxisFromZero")}
                    />
                    Начинать ось Y с 0
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.hideYAxis}
                        onClick={() => changeBoolField("hideYAxis")}
                    />
                    Скрыть ось Y
                </Grid>
                {!!showSlider && <Grid container width='300px' flexDirection='column' alignItems='flex-start' gap='5px' paddingLeft='10px'>
                    <Grid>Диапазон</Grid>
                    <Slider
                        min={min}
                        max={max}
                        value={yearRange}
                        onChange={changeSlider}
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </Grid>}
            </Grid>
            {/* <Grid>
                <Divider orientation="vertical" />
            </Grid> */}
            <SDataCompare isNotCompare={isNotCompare} />
        </Grid>
    );
};
