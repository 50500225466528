import { useState, useCallback, useEffect, useMemo } from "react";
import { useLazyGetSDataByFormulaQuery } from "../../store/api/vizualization.api";
import { useSelector } from "react-redux";
import { getRaceYearLimit } from "./sDataPage.utils";

export const useSDataParams = (defaultParams) => {
    const [params, setParams] = useState(defaultParams);

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }));
    };

    const changeNumberField = (name, event) => {
        const value = Number(event.target.value);
        setParams((prev) => ({ ...prev, [name]: value }));
    };

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }));
    };

    const resetParams = useCallback(() => {
        setParams(defaultParams);
    }, [defaultParams]);

    const actions = {
        changeTextField,
        changeBoolField,
        changeNumberField,
        resetParams,
    };

    return { params, actions };
};


export const useSDataByFormula = (indicatorId, countryId) => {
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);
    const selectedFormula = useSelector((state) => state.sDataSlice.formula);
    const currentFormulaRegion = useSelector((state) => state.sDataSlice.formulaRegion);

    const [getSDataByFormula] = useLazyGetSDataByFormulaQuery();
    const [sData, setSData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const calcData = useCallback(async (analyticsObjects, formula, currentFormulaRegion) => {

        const formulaRegionId = currentFormulaRegion?.id ?? 'all';

        const currentFormulaId = ['sum', 'izmproc', 'proc']?.includes(formula?.bitrixID) ? 'spec' : formula?.bitrixID;
        const currentSproc = ['sum', 'izmproc', 'proc']?.includes(formula?.bitrixID) ? formula?.bitrixID : undefined;
        const currentRegval = ['proc']?.includes(formula?.bitrixID) ? formulaRegionId : undefined;

        const promises = analyticsObjects?.map((item) => {
            return getSDataByFormula({
                indicatorId: indicatorId,
                countryId: countryId,
                formulaId: currentFormulaId,
                sproc: currentSproc,
                regval: currentRegval,
                analyticObjectId: item.bitrixID,
            });
        })

        Promise.all(promises).then((response) => {
            const responseArray = response.map((item) => {
                const currentAnaliticObjectId = item.originalArgs?.analyticObjectId;
                const currentAnaliticObject = analyticsObjects.find((item) => item.bitrixID === currentAnaliticObjectId);
                return {
                    analiticObjectId: item.originalArgs?.analyticObjectId,
                    analiticObject: currentAnaliticObject,
                    data: item?.data,
                }
            });
            setSData(responseArray);
            setIsLoading(false);
        });
    }, [indicatorId, countryId, getSDataByFormula]);

    useEffect(() => {
        if (selectedAnalyticsObjects?.length > 0 && selectedFormula) {
            setIsLoading(true);
            calcData(selectedAnalyticsObjects, selectedFormula, currentFormulaRegion);
        }
    }, [selectedAnalyticsObjects, selectedFormula, currentFormulaRegion]);


    return { data: sData, isLoading };
}

export const useSDataSliderParams = (data) => {
    const [yearRange, setYearRange] = useState([0, 0]);

    const { min, max, marks, showSlider } = useMemo(() => {
        const [min, max] = getRaceYearLimit(data?.data);
        const showSlider = max > min;
        const marks = [{ value: min, label: min }, { value: max, label: max }];
        return { min, max, marks, showSlider }
    }, [data]);

    useEffect(() => {
        if (!min || !max) return;
        setYearRange([min, max]);
    }, [min, max]);

    const changeSlider = (event) => {
        const value = event.target.value;
        setYearRange(value);
    }

    const resetSlider = useCallback(() => {
        if (!min || !max) return;
        setYearRange([min, max]);
    }, [min, max])

    return { showSlider, min, max, marks, yearRange, changeSlider, resetSlider }
}