import { Button, CircularProgress, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


export const Default = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // navigate('/indicator/22843/country/6117');
        navigate('/indicator/12276/country/6071');
    }, []);

    return (
        <Grid container height='100vh' width='100vw' justifyContent='center' alignItems='center' flexDirection='column' gap='30px'>
            <CircularProgress />
            {/* <img src="https://ru.statbase.org/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} /> */}
            {/* <Grid fontSize='30px' fontWeight='600'>Упс... Кажется что-то пошло не так</Grid> */}
        </Grid>
    );
}
