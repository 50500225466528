import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { isColor } from "../../utils/chart.utils";
import { mockCompareData, mockLoadedData } from "./baseTestChart.mocks";
import { getFormulaName, getUnit } from "./baseTestChart.utils";

export const useBaseTestChart = (data, compareData, params, chartName, series1Name, series2Name, indicator, formula, selectedOrganization) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        // strArray.push(`\troot.setThemes([
        //     am5themes_Animated.new(root)
        // ]);\r\n`);
        strArray.push(`\tvar chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ${params.zoomEnable ?
                `wheelX: "panX",
                wheelY: "zoomX",
                ` : ''}layout: root.verticalLayout
            })
        );\r\n`);


        if (params.showTitle) {
            strArray.push(`\tchart.topAxesContainer.children.push(am5.Label.new(root, {
            text: '${getFormulaName(indicator, formula, selectedOrganization)} | ${getUnit(indicator, formula)}',
            fontSize: 20,
            fontWeight: "400",
            x: am5.p50,
            centerX: am5.p50
        }));\r\n`);
        }

        if (params.scrollbarEnable) {
            strArray.push(`\tchart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));\r\n`);
        }

        strArray.push(`\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`);
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);

        strArray.push(`\tvar jsonData = '${JSON.stringify(data)}';\r\n`);
        strArray.push(`\tvar data = JSON.parse(jsonData);\r\n`);
        if (compareData) {
            strArray.push(`\tvar jsonCompareData = '${JSON.stringify(compareData)}';\r\n`);
            strArray.push(`\tvar compareData = JSON.parse(jsonCompareData);\r\n`);
        }
        strArray.push(`\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`);
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);
        strArray.push(`\tvar xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "year"
            })
        );\r\n`);
        strArray.push(`\txAxis.data.setAll(data);\r\n`);


        strArray.push(`\tvar series1 = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: '${series1Name}',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "year",
                minBulletDistance: 50,
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{categoryX}: {valueY} ${getUnit(indicator, formula)}"
                })
            })
        );\r\n`);
        strArray.push(`\tseries1.data.setAll(data);\r\n`);

        if (compareData) {
            strArray.push(`\tvar series2 = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: '${series2Name}',
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "year",
                    minBulletDistance: 50,
                    sequencedInterpolation: true,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{categoryX}: {valueY} ${getUnit(indicator, formula)}"
                    })
                })
            );\r\n`);
            strArray.push(`\tseries2.data.setAll(compareData);\r\n`);
        }

        if (isColor(params.color)) {
            strArray.push(`\tseries1.set("fill", am5.color('${params.color}'));\r\n`);
            strArray.push(`\tseries1.set("stroke", am5.color('${params.color}'));\r\n`);
        }

        if (isColor(params.compareColor) && compareData) {
            strArray.push(`\tseries2.set("fill", am5.color('${params.compareColor}'));\r\n`);
            strArray.push(`\tseries2.set("stroke", am5.color('${params.compareColor}'));\r\n`);
        }

        strArray.push(`\tvar yRenderer = yAxis.get("renderer");\r\n`);
        strArray.push(`\tyRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });\r\n`);

        strArray.push(`\tseries1.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
        strArray.push(`\tseries1.columns.template.set("interactive", true);\r\n`);

        if (compareData) {
            strArray.push(`\tseries2.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
            strArray.push(`\tseries2.columns.template.set("interactive", true);\r\n`);
        }

        if (isColor(params.hoverColor)) {
            strArray.push(`\tseries1.columns.template.states.create("hover", {
                fill: am5.color('${params.hoverColor}'),
                stroke: am5.color('${params.hoverColor}')
            });\r\n`);
            if (compareData) {
                strArray.push(`\tseries2.columns.template.states.create("hover", {
                    fill: am5.color('${params.hoverColor}'),
                    stroke: am5.color('${params.hoverColor}')
                });\r\n`);
            }
        }

        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50)
        }));\r\n`);
            strArray.push(`\tlegend.data.setAll(chart.series.values);\r\n`);
        }

        strArray.push(`\tseries1.appear(500);\r\n`);
        if (compareData) {
            strArray.push(`\tseries2.appear(500);\r\n`);
        }

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [data, compareData, params, chartName, series1Name, series2Name, indicator, formula, selectedOrganization]);

    return { htmlDiagram, createChart, chartName }
}

export const useGetData = (indicatorId, countryId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_ax_graph_country.php?indicator=${indicatorId}&country=${countryId}&couname=statbase_graph25179_5977`, [indicatorId, countryId]);

    // useEffect(() => {
    //     setData(mockLoadedData);
    // }, []);

    useEffect(() => {
        if (!indicatorId || !countryId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, countryId]);

    return { data, isLoading }
}

export const useGetCountriesByIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_countries_by_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useGetCompareData = (indicatorId, countryId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_ax_graph_country.php?indicator=${indicatorId}&country=${countryId}&couname=statbase_graph25179_5977`, [indicatorId, countryId]);

    // useEffect(() => {
    //     setData(mockCompareData);
    // }, []);

    useEffect(() => {
        if (!indicatorId || !countryId) {
            setData(null);
            return;
        }
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, countryId]);

    return { data, isLoading }
}

export const useGetCompareIndicators = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_compare_indicators.php?indicator=${indicatorId}`, [indicatorId]);


    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        }
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useGetFormulasIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_formulas_indicator.php?indicator=${indicatorId}`, [indicatorId]);


    useEffect(() => {
        if (!indicatorId) {
            setData(null);
            return;
        }
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useGetOneIndicator = (indicatorId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_indicator.php?indicator=${indicatorId}`, [indicatorId]);

    useEffect(() => {
        if (!indicatorId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId]);

    return { data, isLoading }
}

export const useGetOneCountry = (countryId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_one_country.php?country=${countryId}`, [countryId]);

    useEffect(() => {
        if (!countryId) return;
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [countryId]);

    return { data, isLoading }
}

export const useGetAllIndicators = () => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_indicators.php`, []);

    useEffect(() => {
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    return { data, isLoading }
}

export const useGetAllCountries = () => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => `${basePath}/get_countries.php`, []);

    useEffect(() => {
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, []);

    return { data, isLoading }
}

export const useGetDataByFormula = (indicatorId, countryId, formulaId, organizationId) => {

    const basePath = 'https://topic.ws/include/confcountrynew';
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const url = useMemo(() => {
        if (['sum', 'izmproc'].includes(formulaId)) {
            return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&country=${countryId}&frmreq=spec&sproc=${formulaId}`
        }
        if (formulaId === 'proc') {
            return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&country=${countryId}&frmreq=0&sproc=${formulaId}&regval=${organizationId}&regsel=mo`
        }
        return `${basePath}/get_graph_country_f.php?indicator=${indicatorId}&country=${countryId}&frmreq=${formulaId}`;
    }, [indicatorId, countryId, formulaId, organizationId]);

    useEffect(() => {
        if (!indicatorId || !countryId || !formulaId || formulaId === 0) {
            setData(null);
            return;
        };
        setIsLoading(true);
        axios.get(url)
            .then(function (response) {
                setData(response?.data ?? null);
                setIsLoading(false);
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }, [indicatorId, countryId, formulaId, organizationId]);

    return { data, isLoading }
}

export const useChartParams = () => {

    const [params, setParams] = useState({
        color: '#67b7dc',
        compareColor: '#69b9ae',
        hoverColor: '#297373',
        zoomEnable: true,
        scrollbarEnable: true,
        width: "100%",
        height: "600px",
        showTitle: true,
        showLegend: true,
    });

    const changeTextField = (name, event) => {
        const value = event.target.value;
        setParams((prev) => ({ ...prev, [name]: value }))
    }

    const changeBoolField = (name) => {
        setParams((prev) => ({ ...prev, [name]: !prev[name] }))
    }

    return { changeTextField, changeBoolField, params }
}

export const useSliderParams = (data) => {

    const [yearRange, setYearRange] = useState(null);

    const { min, max, marks } = useMemo(() => {
        const values = data?.map((item) => Number(item.year)) ?? [];
        const min = Math.min(...values);
        const max = Math.max(...values);
        const marks = [{ value: min, label: min }, { value: max, label: max }];
        return { min, max, marks }
    }, [data]);

    useEffect(() => {
        if (!min || !max) return;
        setYearRange([min, max]);
    }, [min, max]);

    const changeSlider = (event) => {
        const value = event.target.value;
        setYearRange(value);
    }

    return { min, max, marks, yearRange, changeSlider }
}