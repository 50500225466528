import { useCallback } from "react";
import { Chip, Grid, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const textGridSx = {
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "10px",
  textAlign: "left",
  width: "100%",
  // gap: '10px',
  // flexDirection: 'column',
  // paddingLeft: '10px',
  // paddingTop: '10px',
  // paddingBottom: '10px',
};

const organizationSx = {
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "10px",
  textAlign: "left",
  width: "100%",
  gap: "10px",
  alignItems: "center",
};

const autocompleteSx = {
  width: "30%",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const ChartTypeSelector = ({ chartType, setChartType }) => {
  const onChangeHandler = useCallback(
    (type) => {
      setChartType?.(type);
    },
    [setChartType]
  );

  return (
    <Grid container flexDirection="column" alignItems="flex-start" gap="5px">
      <Grid container gap="5px">
        <Chip
          key={`base_column_type`}
          color={chartType == "columns" ? "primary" : "default"}
          label="Columns"
          onClick={() => onChangeHandler("columns")}
        />
        <Chip
          key={`base_line_type`}
          color={chartType === "line" ? "primary" : "default"}
          label="Line"
          onClick={() => onChangeHandler("line")}
        />
      </Grid>
    </Grid>
  );
};
