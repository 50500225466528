import { Chip, Divider, Grid } from "@mui/material";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";



export const VisualizationLinks = ({ type, showColumncountries, showPiechart }) => {

    const { indicatorId, year, countryId } = useParams();
    const navigate = useNavigate();

    const onClick = useCallback((clickType) => {
        if (type === clickType) return;
        if (['treemap', 'piechart', 'race', 'columncountries', 'map', 'line'].includes(clickType)) {
            navigate(`/indicator/${indicatorId}/${clickType}`);
        }
        if (clickType == 'column') {
            // navigate(`/indicator/${indicatorId}/country/${countryId ?? 6117}`);
            navigate(`/indicator/${indicatorId}/country/${countryId ?? 6071}`);
        }
    }, [indicatorId, year, countryId]);


    return (
        <Grid container flexDirection='row' alignItems='flex-start' gap='15px'>
            {/* <Grid container flexDirection='column' width='auto' alignItems='flex-start' gap='5px'>
                <Grid sx={{ fontWeight: 700 }}>По странам</Grid>
                <Grid container width='auto' gap='5px'>
                    <Chip
                        key={`column`}
                        color={(type === 'column') ? 'primary' : 'default'}
                        label='Columns'
                        onClick={() => onClick('column')}
                    />
                </Grid>
            </Grid> */}
            {/* <Grid><Divider orientation="vertical" /></Grid> */}
            {/* <Grid container flexDirection='column' width='auto' alignItems='flex-start' gap='5px'>
                <Grid sx={{ fontWeight: 700 }}>По миру и регионам</Grid>
                <Grid container width='auto' gap='5px'>
                    <Chip
                        key={`columncountries`}
                        color={(type === 'columncountries') ? 'primary' : 'default'}
                        label='Columns'
                        onClick={() => onClick('columncountries')}
                    />
                    <Chip
                        key={`treemap`}
                        color={(type === 'treemap') ? 'primary' : 'default'}
                        label='Treemap'
                        onClick={() => onClick('treemap')}
                    />
                    <Chip
                        key={`piechart`}
                        color={(type === 'piechart') ? 'primary' : 'default'}
                        label='PieChart'
                        onClick={() => onClick('piechart')}
                    />
                    <Chip
                        key={`race`}
                        color={(type === 'race') ? 'primary' : 'default'}
                        label='RaceChart'
                        onClick={() => onClick('race')}
                    />
                </Grid>
            </Grid> */}
            <Grid container gap='5px'>
                {/* <Chip
                    key={`column`}
                    color={(type === 'column') ? 'primary' : 'default'}
                    label='Columns'
                    onClick={() => onClick('column')}
                /> */}
                {showColumncountries && <Chip
                    key={`columncountries`}
                    color={(type === 'columncountries') ? 'primary' : 'default'}
                    label='Columns'
                    onClick={() => onClick('columncountries')}
                />}
                {showColumncountries && <Chip
                    key={`line`}
                    color={(type === 'line') ? 'primary' : 'default'}
                    label='Lines'
                    onClick={() => onClick('line')}
                />}
                <Chip
                    key={`treemap`}
                    color={(type === 'treemap') ? 'primary' : 'default'}
                    label='Treemap'
                    onClick={() => onClick('treemap')}
                />
                {showPiechart && <Chip
                    key={`piechart`}
                    color={(type === 'piechart') ? 'primary' : 'default'}
                    label='PieChart'
                    onClick={() => onClick('piechart')}
                />}
                <Chip
                    key={`race`}
                    color={(type === 'race') ? 'primary' : 'default'}
                    label='RaceChart'
                    onClick={() => onClick('race')}
                />
                <Chip
                    key={`map`}
                    color={(type === 'map') ? 'primary' : 'default'}
                    label='Map'
                    onClick={() => onClick('map')}
                />

            </Grid>
        </Grid >
    );
}
