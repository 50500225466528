import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCountriesQuery, useGetIndicatorsQuery, useGetSDataIndicatorsByCountryQuery, useGetSDataIndicatorsQuery } from "../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import { resetAnalyticsObject, setYear } from "../../store/reducer/sDataSlice";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const autocompleteSx = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const SDataYearSelect = ({ data }) => {
    const dispatch = useDispatch();
    const selectedYear = useSelector((state) => state.sDataSlice.year);

    const setYearHandler = useCallback((value) => {
        dispatch(setYear(value?.id ?? null));
    }, [dispatch]);

    const yearDictionary = useMemo(() => {
        const sDataKeys = Object.keys(data.data);
        const yearValues = sDataKeys.flatMap((analyticsObjectKey) => {
            const currentData = data.data[analyticsObjectKey];
            return Object.keys(currentData);
        });

        const yearsSet = new Set(yearValues);

        const yearArray = [...yearsSet].sort((a, b) => Number(b) - Number(a));
        return yearArray.map((item) => ({ id: item, label: item }));
    }, [data]);

    const defaultValue = useMemo(() => {
        if (selectedYear) return { id: selectedYear, label: selectedYear };
        if (!yearDictionary) return undefined;
        const yearArray = yearDictionary.map((item) => Number(item.id));
        const maxYear = Math.max(...yearArray);
        return { id: maxYear, label: maxYear };
    }, [yearDictionary, selectedYear]);

    return (
        <Grid container sx={gridSx}>
            <Autocomplete
                size="small"
                onChange={(_, value) => setYearHandler(value)}
                options={yearDictionary}
                value={defaultValue}
                defaultValue={defaultValue}
                isOptionEqualToValue={(option, value) => option?.id == value?.id}
                sx={autocompleteSx}
                renderInput={(params) => <TextField {...params} label="Год" />}
            />
        </Grid>
    );
}
